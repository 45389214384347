import { createMuiTheme } from "@material-ui/core";

// colors
const primary = "#0D5AE5";
const secondary = "#01a350";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

const theme = () =>
  createMuiTheme({
    shadows: ["none"],
    palette: {
      primary: { main: primary },
      secondary: { main: secondary },
      common: {
        
      },
      warning: {
        light: warningLight,
        main: warningMain,
        dark: warningDark
      },
      // Used to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      background: {

      },
      display: {

      }
    },
    breakpoints: {
      // Define custom breakpoint values.
      // These will apply to Material-UI components that use responsive
      // breakpoints, such as `Grid` and `Hidden`. You can also use the
      // theme breakpoint functions `up`, `down`, and `between` to create
      // media queries for these breakpoints
      values: {
        xl,
        lg,
        md,
        sm,
        xs
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: ['"Open Sans"', "sans-serif"],
      htmlFontSize: 14
    },
  });

export default theme;
