import { useEffect, useMemo, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import styles from "./styles/CustomerOverview.module.scss";

import { useTranslation } from "react-i18next";
import customerApi from "../../../../api/customerApi";
import { Card } from "@material-ui/core";
import FiltersContainer from "../../../../components/FiltersContainer";
import BaseDatePicker2 from "../../../../components/Form/BaseDatePicker2";
import moment from "moment";
import { useForm } from "react-hook-form";
import Flex from "../../../../components/Flex";

const options_result_phone_num = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: false,
    animateScale: false
  },
  cutout: 40,
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      align: "start",
      padding: 8, // Thêm khoảng cách giữa các mục trong chú thích (giống margin-top)
      labels: {
        usePointStyle: true,
        generateLabels: function (chart) {
          const labels = chart.data.labels;
          const dataset = chart.data.datasets[0];
          return labels.map((label, index) => ({
            datasetIndex: 0,
            fillStyle:
              dataset.backgroundColor && dataset.backgroundColor[index],
            strokeStyle: dataset.borderColor && dataset.borderColor[index],
            lineWidth: dataset.borderWidth,
            text: `${label}: ${dataset.data[index]}`
          }));
        }
      }
    }
  }
};

const options_result_gender = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: false,
    animateScale: false
  },
  cutout: 40,
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      align: "start",
      labels: {
        usePointStyle: true,
        generateLabels: function (chart) {
          const labels = chart.data.labels;
          const dataset = chart.data.datasets[0];
          return labels.map((label, index) => ({
            datasetIndex: 0,
            fillStyle:
              dataset.backgroundColor && dataset.backgroundColor[index],
            strokeStyle: dataset.borderColor && dataset.borderColor[index],
            lineWidth: dataset.borderWidth,
            text: `${label}: ${dataset.data[index]}`
          }));
        }
      }
    }
  }
};

// const textCampaign = {
//   id: 'text-center',
//   beforeDatasetsDraw(chart, args, pluginOptions) {
//     const { ctx } = chart

//     ctx.save()
//     ctx.font = '600 14px sans-serif'
//     ctx.fillStyle = '#000000de'
//     ctx.textAlign = 'center'
//     ctx.textBaseline = 'middle'
//   },
// }
const textCampaign = {
  id: "text-center",
  beforeDatasetsDraw(chart) {
    const { ctx } = chart;
    const total = chart.data.datasets[0].data.reduce((a, b) => a + b, 0); // Tính tổng số lượng
    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2; // Tính tọa độ X trung tâm
    const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2; // Tính tọa độ Y trung tâm

    ctx.save();
    ctx.font = "600 14px sans-serif";
    ctx.fillStyle = "#000000de";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(total.toString(), centerX, centerY); // Vẽ số lượng tổng thể
    ctx.restore();
  }
};

const dateRange = {
  LAST_30D: [
    moment().clone().subtract(30, "days").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD")
  ],
  LAST_3M: [
    moment().clone().subtract(3, "months").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD")
  ],
  LAST_6M: [
    moment().clone().subtract(6, "months").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD")
  ],
  LAST_12M: [
    moment().clone().subtract(12, "months").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD")
  ]
};

const CustomerOverview = ({ ...res }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      date: dateRange["LAST_30D"]
    }
  });

  const [customerStatistic, setCustomerStatistic] = useState({});

  // Đoạn code cũ gây duplicate api user?from=... bị gọi 2 lần
  // useEffect(() => {
  //   (async () => {
  //     // if (!campaignData) return

  //     const date = watch("date");

  //     const params = {
  //       from: moment(date[0]).format("YYYY-MM-DD"),
  //       to: moment(date[1]).format("YYYY-MM-DD")
  //     };

  //     try {
  //       const customersData = await customerApi.getCustomersStatistic(params);
  //       setCustomerStatistic(customersData);
  //     } catch (error) {
  //       console.log("error ==>", error);
  //     }
  //   })();
  // }, [watch("date")]);

  // =========> FIX lỗi trên
  const prevDate = useRef(watch("date")); // Lưu trữ giá trị date trước đó

  useEffect(() => {
    const currentDate = watch("date");
    if (currentDate !== prevDate.current) {
      prevDate.current = currentDate; // Cập nhật giá trị date mới
      (async () => {
        try {
          // console.log("Calling API with date:", currentDate); // Log trước khi gọi API
          const customersData = await customerApi.getCustomersStatistic({
            from: moment(currentDate[0]).format("YYYY-MM-DD"),
            to: moment(currentDate[1]).format("YYYY-MM-DD")
          });
          setCustomerStatistic(customersData);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [watch("date")]);

  const genderData = useMemo(() => {
    return {
      labels: [
        t("dashboard.male"),
        t("dashboard.female"),
        t("dashboard.other")
      ],
      datasets: [
        {
          data: [
            customerStatistic.totalMale,
            customerStatistic.totalFemale,
            customerStatistic.totalOther
          ],
          backgroundColor: ["#a8d5ba", "#4caf50", "#81c784"],
          borderWidth: 0
        }
      ]
    };
  }, [customerStatistic]);

  const phoneNumberData = useMemo(() => {
    return {
      labels: [
        t("dashboard.have_phone_number"),
        t("dashboard.not_have_phone_number")
      ],
      datasets: [
        {
          data: [
            customerStatistic.totalHavePhone,
            customerStatistic.totalNotHavePhone
          ],
          backgroundColor: ["#a8d5ba", "#4caf50"],
          borderWidth: 0
        }
      ]
    };
  }, [customerStatistic]);

  const [selectedFilter, setSelectedFilter] = useState("LAST_30D");

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);

    if (filter === "CUSTOM") {
      return;
    }

    setValue("date", dateRange[filter]);

    // Gọi hàm để tải lại danh sách kịch bản với bộ lọc mới
    // Ví dụ: loadScenarioList(filter);
  };

  return (
    <Card
      style={{
        marginTop: "20px",
        marginBottom: "20px",
        borderRadius: "12px",
        width: "100%",
        // backgroundColor: "#eef2f6"
      }}
      {...res}
    >
      <FiltersContainer
        selectedFilter={selectedFilter}
        handleFilterChange={handleFilterChange}
        options={[
          {
            label: t("common.btn.time_periods.30_days_ago"), // 30 Ngày trước
            value: "LAST_30D",
            color: "#007bff"
          },
          {
            label: t("common.btn.time_periods.3_months_ago"), // 3 Tháng trước
            value: "LAST_3M",
            color: "#007bff"
          },
          {
            label: t("common.btn.time_periods.6_months_ago"), // 6 Tháng trước
            value: "LAST_6M",
            color: "#007bff"
          },
          {
            label: t("common.btn.time_periods.12_months_ago"), // 12 Tháng trước
            value: "LAST_12M",
            color: "#007bff"
          },
          {
            label: t("common.btn.time_periods.custom"), // Tùy chọn
            value: "CUSTOM",
            color: "#007bff"
          }
        ]}
      />

      <div
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          padding: "16px"
        }}
      >
        <h3
          style={{
            margin: 0,
            marginBottom: "12px"
          }}
        >
          {t("dashboard.customer_statistic_by_time")}
        </h3>

        {selectedFilter === "CUSTOM" && (
          <Flex justify="flex-end">
            <BaseDatePicker2
              name={"date"}
              control={control}
              defaultValue={[null, null]}
              styles={{
                marginTop: "20px"
              }}
            />
          </Flex>
        )}

        <div className={styles.wrapper} id="customer_statistic">
          <div className={styles.statsChartWrapper}>
            <div
              style={{
                marginRight: "52px"
              }}
            >
              <p className={styles.overallNumber}>
                {customerStatistic.totalCus}
              </p>
            </div>

            <div className={styles.chartItem}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <div className={styles.chartItemContent}>
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor:
                        genderData.datasets[0].backgroundColor[0],
                      display: "inline-block",
                      marginRight: "8px",
                      marginTop: "4px"
                    }}
                  ></span>
                  <div>
                    <p className={styles.statsTitle}>{t("dashboard.male")}</p>
                    <p className={styles.statsNumber}>
                      {customerStatistic.totalMale}
                    </p>
                    <p
                      className={styles.statsPercent}
                      style={{
                        color: "#a8d5ba"
                      }}
                    >
                      {customerStatistic.percentMale?.toFixed(2)}%
                    </p>
                  </div>
                </div>

                <div className={styles.chartItemContent}>
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor:
                        genderData.datasets[0].backgroundColor[1],
                      display: "inline-block",
                      marginRight: "8px",
                      marginTop: "4px"
                    }}
                  ></span>
                  <div>
                    <p className={styles.statsTitle}>{t("dashboard.female")}</p>
                    <p className={styles.statsNumber}>
                      {customerStatistic.totalFemale}
                    </p>
                    <p
                      className={styles.statsPercent}
                      style={{
                        color: "#4caf50"
                      }}
                    >
                      {customerStatistic.percentFemale?.toFixed(2)}%
                    </p>
                  </div>
                </div>

                <div className={styles.chartItemContent}>
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor:
                        genderData.datasets[0].backgroundColor[2],
                      display: "inline-block",
                      marginRight: "8px",
                      marginTop: "4px"
                    }}
                  ></span>
                  <div>
                    <p className={styles.statsTitle}>{t("dashboard.other")}</p>
                    <p className={styles.statsNumber}>
                      {customerStatistic.totalOther}
                    </p>
                    <p
                      className={styles.statsPercent}
                      style={{
                        color: "#81c784"
                      }}
                    >
                      {customerStatistic.percentOther?.toFixed(2)}%
                    </p>
                  </div>
                </div>
              </div>

              <div className={styles.phoneNumChart}>
                <div
                  className={styles.chart}
                  style={{
                    height: "160px"
                  }}
                >
                  <Doughnut
                    data={genderData}
                    options={options_result_gender}
                    plugins={[textCampaign]}
                  />
                </div>
              </div>
            </div>

            <div className={styles.chartItem}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <div className={styles.chartItemContent}>
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor:
                        genderData.datasets[0].backgroundColor[0],
                      display: "inline-block",
                      marginRight: "8px",
                      marginTop: "4px"
                    }}
                  ></span>
                  <div>
                    <p className={styles.statsTitle}>
                      {t("dashboard.have_phone_number")}
                    </p>
                    <p className={styles.statsNumber}>
                      {customerStatistic.totalHavePhone}
                    </p>
                    <p
                      className={styles.statsPercent}
                      style={{
                        color: "#a8d5ba"
                      }}
                    >
                      {customerStatistic.percentHavePhone?.toFixed(2)}%
                    </p>
                  </div>
                </div>

                <div className={styles.chartItemContent}>
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor:
                        genderData.datasets[0].backgroundColor[1],
                      display: "inline-block",
                      marginRight: "8px",
                      marginTop: "4px"
                    }}
                  ></span>
                  <div>
                    <p className={styles.statsTitle}>
                      {t("dashboard.not_have_phone_number")}
                    </p>
                    <p className={styles.statsNumber}>
                      {customerStatistic.totalNotHavePhone}
                    </p>
                    <p
                      className={styles.statsPercent}
                      style={{
                        color: "#4caf50"
                      }}
                    >
                      {customerStatistic.percentNotHavePhone?.toFixed(2)}%
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.phoneNumChart}>
                <div
                  className={styles.chart}
                  style={{
                    height: "160px"
                  }}
                >
                  <Doughnut
                    data={phoneNumberData}
                    options={options_result_phone_num}
                    plugins={[textCampaign]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CustomerOverview;
