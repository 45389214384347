import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import IMAGES from "../../images";
import Container from "@material-ui/core/Container";
import ButtonComponent from "../../components/Button";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import FacebookIcon from "@material-ui/icons/Facebook";
import { pathKeys } from "../../constants";
import FormDialog from "../../components/FormDialog";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import Flex from "components/Flex";
import ChangeLanguage from "components/ChangeLanguage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundSize: "cover",
    background: "no-repeat top",
    position: "relative",
    backgroundColor: "#1BBB51",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "#ffffff"
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },

  wrapperLogin: {
    width: "100%",
    maxWidth: "500px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },

  mobileWrapperLogin: {
    width: "100%",
    maxWidth: "500px",
    position: "absolute",
    paddingLeft: "16px",
    paddingRight: "16px",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block"
    }
  },

  whiteLogo: {
    width: "auto",
    height: "100%",
    transform: "rotate(20deg)",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },

  top: {
    borderBottom: "1px solid #f0f0f0",
    padding: "1.25rem 0 3rem 0",
    textAlign: "center"
  },
  bottomTitle: {
    marginTop: 23,
    marginBottom: 0,
    fontSize: 21,
    color: "#8898aa",
    textAlign: "center",
    color: "rgba(41, 39, 39, 0.40)",
    fontWeight: 400,
    lineHeight: "130%",
    letterSpacing: "-0.18px",
    textAlign: "center"
  },
  textTitle: {
    marginTop: 8,
    marginBottom: 8,
    fontSize: 14,
    color: "#8898aa",
    textAlign: "center",
    color: "#292727",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "130%",
    letterSpacing: "-0.18px",
    textAlign: "center"
  },
  appTitle: {
    color: "#292727",
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "130%",
    letterSpacing: "-0.24px",
    textAlign: "center"
  },
  botIcon: {
    width: "200px",
    height: "auto",
    display: "block",
    margin: "0 auto",
    marginTop: 4,
    marginBottom: 12
  },
  form: {
    width: "100%"
  },
  input: {
    marginBottom: 20,
    fontSize: 18
  },
  button: {
    marginTop: 40
  },
  facebook: {
    marginBottom: 50,
    borderRadius: "20px !important",
    background: "#EEE",
    fontSize: 16,
    "& $iconFacebook": {
      color: "#3B5998"
    },
    "&:hover": {
      background: "#3B5998",
      color: "white",
      "& $iconFacebook": {
        color: "#ffffff"
      }
    }
  },
  iconFacebook: {
    marginRight: 5,
    display: "flex",
    color: "#3B5998"
  },
  error: {
    color: "red",
    textAlign: "center",
    marginBottom: 0,
    marginTop: 12
  },
  forgotPassword: {
    marginTop: 12,
    fontSize: 14,
    color: "#292727",
    textAlign: "center",
    textDecoration: "underline",
    "&:hover": {
      color: "#29272799",
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  wrapChangePassword: {
    padding: "0px 15px 40px 15px"
  },
  titleChangePassword: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 15,
    color: "#05AE34"
  },
  inputForgotPassword: {
    width: "300px !important",
    marginRight: 15,
    position: "relative",
    top: 10
  },
  wrapForgotPasswordInput: {
    textAlign: "center"
  },
  success: {
    color: "#05AE34",
    textAlign: "center"
  },
  banner: {
    position: "fixed",
    top: 30
  }
}));

const LoginView = (props) => {
  const {
    login,
    getAccount,
    handleSubmitEmail,
    statusLogined,
    onSubmitForgotPassword,
    forgotPassword,
    loading
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPasswordPopup, setIsForgotPasswordPopup] = useState(false);
  const [emailForgotPassword, setEmailForgotPassword] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (getAccount.token) {
      history.push(pathKeys.HOME);
    }
  }, [getAccount]);

  useEffect(() => {
    if (forgotPassword) {
      setEmailForgotPassword("");
    }
  }, [forgotPassword]);

  const handleClickButtonFacebook = async () => {
    localStorage.removeItem("alertGiftBox");
    const searchParam = new URLSearchParams(window.location.search);
    const ref = searchParam.get("ref");
    login(ref);
  };

  const handleChange = (key, value) => {
    switch (key) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    const searchParam = new URLSearchParams(window.location.search);
    const ref = searchParam.get("ref");
    const payload = { email, password };
    if (ref) payload.ref = ref;

    handleSubmitEmail(payload);
  };

  const handleForgotPasswordPopup = () => {
    setIsForgotPasswordPopup(true);
  };

  const handleCloseForgotPasswordPopup = () => {
    setIsForgotPasswordPopup(false);
  };
  const handleForgotPassword = () => {
    if (emailForgotPassword) {
      const params = {
        email: emailForgotPassword
      };
      onSubmitForgotPassword(params);
    }
  };

  const handleChangeForgotPassword = (key, value) => {
    switch (key) {
      case "emailForgotPassword":
        setEmailForgotPassword(value);
        break;

      default:
        break;
    }
  };

  const background = useMemo(() => {
    return i18n.language?.substr(0, 2) === "vi"
      ? IMAGES.BACKGROUND
      : IMAGES.BACKGROUND_INTE;
  }, [i18n.language]);
  return (
    <div
      className={classes.root}
      // style={{ backgroundImage: `url(${background})` }}
    >
      {loading && <Loading />}
      <div
        style={{
          position: "absolute",
          left: "-10%",
          bottom: "-30%",
          overflow: "hidden",
          width: "100%",
          height: "100%"
        }}
      >
        <img
          src="/image/white-logo.svg"
          alt="white-logo"
          className={classes.whiteLogo}
        />
      </div>

      {/* man hinh mobile, tablet */}
      <div className={classes.mobileWrapperLogin}>
        <Flex column justify="space-between">
          <Container fixed>
            <div className={classes.bottom}>
              <p className={classes.bottomTitle}>{t("login.welcom_to")}</p>
              <p className={classes.appTitle}>
                {t("login.bulk_messaging_application")}
              </p>
              <img
                src="https://bombot.vn/wp-content/uploads/2022/01/bombot-logo-tet.png"
                className={classes.botIcon}
              />
              <FormControl className={classes.form}>
                <Input
                  className={classes.input}
                  id="input-name"
                  label="Standard"
                  variant="outlined"
                  placeholder={"Email"}
                  value={email}
                  onChange={(event) =>
                    handleChange("email", event.target.value)
                  }
                  startAdornment={
                    <InputAdornment position="start" label="Standard">
                      <EmailIcon />
                    </InputAdornment>
                  }
                />
                <Input
                  className={classes.input}
                  id="input-password"
                  type="password"
                  placeholder={t("update.form.plh_pass")}
                  value={password}
                  onChange={(event) =>
                    handleChange("password", event.target.value)
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  }
                />
                <span
                  className={classes.forgotPassword}
                  onClick={() => handleForgotPasswordPopup()}
                >
                  {t("login.forgot_password.title")}
                </span>

                <ButtonComponent
                  className="btn--rounder--large mt-3"
                  title={t("common.btn.login")}
                  backgroundColor="#05AE34"
                  color="white"
                  handleClickButton={() => handleSubmit()}
                />

                <p
                  style={{
                    fontSize: 14,
                    textAlign: "center",
                    paddingTop: "12px",
                    paddingBottom: "12px"
                  }}
                >
                  {t("login.or")}
                </p>

                <ButtonComponent
                  className={classes.facebook}
                  title={t("login.login_by_facebook")}
                  handleClickButton={() => handleClickButtonFacebook()}
                  icon={
                    <span className={classes.iconFacebook}>
                      <FacebookIcon />
                    </span>
                  }
                />

                {!statusLogined && statusLogined != null && (
                  <p className={classes.error}>{t("noti.login_error")}</p>
                )}
              </FormControl>
            </div>
          </Container>
          <Flex justify="center" className="w-100">
            <ChangeLanguage style={{ width: "100%" }} />
          </Flex>
        </Flex>
      </div>

      {/* man hinh pc */}
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          overflow: "hidden",
          width: "55%",
          height: "100%"
        }}
      >
        <div style={{ position: "relative", width: "auto", height: "100%" }}>
          <img
            src="/image/asl-white.svg"
            alt="asl-white"
            style={{ width: "auto", height: "100%" }}
          />

          <div className={classes.wrapperLogin}>
            <Flex column justify="space-between">
              <Container fixed>
                <div className={classes.bottom}>
                  <p className={classes.bottomTitle}>{t("login.welcom_to")}</p>
                  <p className={classes.appTitle}>
                    {t("login.bulk_messaging_application")}
                  </p>
                  <img
                    src="https://bombot.vn/wp-content/uploads/2022/01/bombot-logo-tet.png"
                    className={classes.botIcon}
                  />
                  <FormControl className={classes.form}>
                    <Input
                      className={classes.input}
                      id="input-name"
                      label="Standard"
                      variant="outlined"
                      placeholder={"Email"}
                      value={email}
                      onChange={(event) =>
                        handleChange("email", event.target.value)
                      }
                      startAdornment={
                        <InputAdornment position="start" label="Standard">
                          <EmailIcon />
                        </InputAdornment>
                      }
                    />
                    <Input
                      className={classes.input}
                      id="input-password"
                      type="password"
                      placeholder={t("update.form.plh_pass")}
                      value={password}
                      onChange={(event) =>
                        handleChange("password", event.target.value)
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      }
                    />
                    <span
                      className={classes.forgotPassword}
                      onClick={() => handleForgotPasswordPopup()}
                    >
                      {t("login.forgot_password.title")}
                    </span>

                    <ButtonComponent
                      className="btn--rounder--large mt-3"
                      title={t("common.btn.login")}
                      backgroundColor="#05AE34"
                      color="white"
                      handleClickButton={() => handleSubmit()}
                    />

                    <p
                      style={{
                        fontSize: 14,
                        textAlign: "center",
                        paddingTop: "12px",
                        paddingBottom: "12px"
                      }}
                    >
                      {t("login.or")}
                    </p>

                    <ButtonComponent
                      className={classes.facebook}
                      title={t("login.login_by_facebook")}
                      handleClickButton={() => handleClickButtonFacebook()}
                      icon={
                        <span className={classes.iconFacebook}>
                          <FacebookIcon />
                        </span>
                      }
                    />

                    {!statusLogined && statusLogined != null && (
                      <p className={classes.error}>{t("noti.login_error")}</p>
                    )}
                  </FormControl>
                </div>
              </Container>
              <Flex justify="center" className="w-100">
                <ChangeLanguage style={{ width: "100%" }} />
              </Flex>
            </Flex>
          </div>
        </div>
      </div>

      <FormDialog
        isOpen={isForgotPasswordPopup}
        handleClose={() => handleCloseForgotPasswordPopup()}
      >
        <div className={classes.wrapChangePassword}>
          <h4 className={classes.titleChangePassword}>
            {t("login.forgot_password.title")}
          </h4>
          <p style={{ textAlign: "center" }}>
            {t("login.forgot_password.description")}
          </p>
          {forgotPassword != null && !forgotPassword && (
            <p className={classes.error}>{t("noti.email_not_exist")}</p>
          )}
          {forgotPassword != null && forgotPassword && (
            <p className={classes.success}>{t("noti.reset_password")}</p>
          )}
          <div className={classes.wrapForgotPasswordInput}>
            <Input
              className={classes.inputForgotPassword}
              id="input-name"
              label="Standard"
              placeholder={"Email"}
              value={emailForgotPassword}
              onChange={(event) =>
                handleChangeForgotPassword(
                  "emailForgotPassword",
                  event.target.value
                )
              }
              startAdornment={
                <InputAdornment position="start" label="Standard">
                  <EmailIcon />
                </InputAdornment>
              }
            />
            <ButtonComponent
              className="btn--rounder"
              title={t("common.btn.confirm")}
              backgroundColor="#FF8000"
              color="white"
              handleClickButton={() => handleForgotPassword()}
            />
          </div>
        </div>
      </FormDialog>
    </div>
  );
};

LoginView.propTypes = {};

LoginView.defaultProps = {};

export default LoginView;
