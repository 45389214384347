import React, { useEffect, useMemo, useRef, useState } from "react";
import homeApi from "../../../../api/homeApi";
import { Card } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import BaseDatePicker2 from "../../../../components/Form/BaseDatePicker2";
import { useForm } from "react-hook-form";
import moment from "moment";
import Flex from "../../../../components/Flex";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import LegendItem from "./LegendItem";

const MessageChart = ({ style, ...res }) => {
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const { control, watch } = useForm({
    defaultValues: {
      date: [moment().subtract(30, "days").toDate(), new Date()]
    }
  });

  // Đoạn code cũ gây duplicate api message?from=... bị gọi 2 lần
  // useEffect(() => {
  //   (async () => {
  //     const date = watch("date");
  //     try {
  //       const res = await homeApi.getMessageStatistic({
  //         from: moment(date[0]).format("YYYY-MM-DD"),
  //         to: moment(date[1]).format("YYYY-MM-DD")
  //       });
  //       setData(res);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, [watch("date")]);

  // =========> FIX lỗi trên
  const prevDate = useRef(watch("date")); // Lưu trữ giá trị date trước đó

  useEffect(() => {
    const currentDate = watch("date");
    if (currentDate !== prevDate.current) {
      prevDate.current = currentDate; // Cập nhật giá trị date mới
      (async () => {
        try {
          // console.log("Calling API with date:", currentDate); // Log trước khi gọi API
          const res = await homeApi.getMessageStatistic({
            from: moment(currentDate[0]).format("YYYY-MM-DD"),
            to: moment(currentDate[1]).format("YYYY-MM-DD")
          });
          setData(res);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [watch("date")]);

  const totalMessages = data?.totalMessages || 0;

  const dataMessages = useMemo(() => {
    return {
      labels: [
        `${t("dashboard.failed_message")}`,
        `${t("dashboard.message_not_sent")}`,
        `${t("dashboard.successful_message")}`
      ],
      datasets: [
        {
          data: [
            data?.totalMessagesFail || 0,
            data?.totalMessagesNotSend || 0,
            data?.totalMessagesSuccess || 0
          ],
          backgroundColor: ["#ff6f91", "#ffb74d", "#81c784"],
          hoverBackgroundColor: ["#ff3d67", "#ff9800", "#4caf50"]
        }
      ]
    };
  }, [data]);

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const textOverlay = useMemo(() => {
    return {
      id: "text-center",
      beforeDatasetsDraw(chart) {
        const { ctx } = chart;
        ctx.save();
        ctx.font = "600 14px sans-serif";
        ctx.fillStyle = "#000000de";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          `${numeral(totalMessages).format("0.[0]a") || 0}`,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y
        );
      }
    };
  }, [totalMessages]);

  const isEmpty = totalMessages === 0;

  return (
    <Card style={{ ...style, padding: "16px", borderRadius: "8px" }} {...res}>
      <Flex
        align="flex-start"
        style={{
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "16px"
        }}
      >
        <h3 style={{ margin: 0, fontSize: "18px", fontWeight: "600" }}>
          {t("dashboard.message")}
        </h3>

        <Flex
          align="flex-end"
          justify="flex-end"
          column
          style={{ textAlign: "right" }}
        >
          <BaseDatePicker2
            name={"date"}
            control={control}
            defaultValue={[null, null]}
          />
          <p
            style={{
              fontSize: "12px",
              fontStyle: "italic",
              marginTop: "4px",
              color: "#8f8f8f"
            }}
          >
            {t("dashboard.message_created_from_to")}
          </p>
        </Flex>
      </Flex>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        {isEmpty ? (
          <Flex
            align="center"
            justify="center"
            style={{ height: "150px", width: "100%" }}
          >
            <p style={{ fontSize: "16px", color: "#9095a0" }}>
              {t("dashboard.no_data")}
            </p>
          </Flex>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%"
            }}
          >
            <div style={{ width: "60%", paddingRight: "16px" }}>
              <Doughnut
                data={dataMessages}
                options={doughnutOptions}
                plugins={[textOverlay]}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
                width: "40%"
              }}
            >
              <LegendItem
                legendColor={dataMessages.datasets[0].backgroundColor[0]}
                number={data?.totalMessagesFail}
                percent={`${parseFloat(
                  (data?.totalMessagesFail / totalMessages) * 100
                ).toFixed(2)} %`}
                title={t("dashboard.failed_message")}
              />

              <LegendItem
                legendColor={dataMessages.datasets[0].backgroundColor[1]}
                number={data?.totalMessagesNotSend}
                percent={`${parseFloat(
                  (data?.totalMessagesNotSend / totalMessages) * 100
                ).toFixed(2)} %`}
                title={t("dashboard.message_not_sent")}
              />

              <LegendItem
                legendColor={dataMessages.datasets[0].backgroundColor[2]}
                number={data?.totalMessagesSuccess}
                percent={`${parseFloat(
                  (data?.totalMessagesSuccess / totalMessages) * 100
                ).toFixed(2)} %`}
                title={t("dashboard.successful_message")}
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default MessageChart;
