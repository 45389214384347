import Flex from "../../../../components/Flex";
import styles from "../../styles.module.scss"; // Ensure you have this file
import CampaignChart from "./CampaignChart";
import CustomerChart from "./CustomerChart";
import CustomerOverview from "./CustomerOverview";
import FanpageChart from "./FanpageChart";
import GenAIChart from "./GenAIChart";
import MessageChart from "./MessageChart";
import ScenarioChart from "./ScenarioChart";
import BombotTagChart from "./BombotTagChart";
import FBTagChart from "./FanpageTagChart";
import { useSelector } from "react-redux";
import { selectGuideListByKey } from "../../../../stores/config/config.selector";
import {
  getIsGuideByKey,
  GUIDE_TYPE_ENUM,
  setIsGuideByKey
} from "../../../../utils/helper";
import { useEffect, useState } from "react";
import Tour from "reactour";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ButtonComponent from "components/Button";
import types from "constants/types";

const DashboardChart = ({
  dashboard,
  campaigns,
  messages,
  scenarios,
  selectedDate = null,
  onDateChange,
  isShowTooltip
}) => {
  const { t } = useTranslation();

  const dataCampaigns = {
    labels: [
      "Campaign Running",
      "Campaign Pending",
      "Campaign Done",
      "Campaign Cancelled"
    ],
    datasets: [
      {
        data: [
          dashboard?.campaign?.totalCampaignRunning || 0,
          dashboard?.campaign?.totalCampaignPending || 0,
          dashboard?.campaign?.totalCampaignDone || 0,
          dashboard?.campaign?.totalCampaignCancel || 0
        ],
        backgroundColor: ["#82ca9d", "#ffc658", "#8884d8", "#AE1607"],
        hoverBackgroundColor: ["#76b89a", "#e6b84d", "#7a6fb8", "#9e1410"]
      }
    ]
  };

  const dataMessages = {
    labels: ["Message", "Message Fail", "Message Not Send", "Message Success"],
    datasets: [
      {
        data: [
          messages?.totalMessages || 0,
          messages?.totalMessagesFail || 0,
          messages?.totalMessagesNotSend || 0,
          messages?.totalMessagesSuccess || 0
        ],
        backgroundColor: ["#82ca9d", "#ffc658", "#8884d8", "#AE1607"],
        hoverBackgroundColor: ["#76b89a", "#e6b84d", "#7a6fb8", "#9e1410"]
      }
    ]
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top"
      }
    }
  };

  const tourOverview = useSelector(
    selectGuideListByKey(GUIDE_TYPE_ENUM.dashboard)
  );

  const [tourConfig, setTourConfig] = useState(null);

  useEffect(() => {
    if (
      getIsGuideByKey(GUIDE_TYPE_ENUM.dashboard) &&
      tourOverview?.length &&
      isShowTooltip
    ) {
      setTourConfig(tourOverview);
      setIsGuideByKey(GUIDE_TYPE_ENUM.dashboard);
    }
  }, [tourOverview, isShowTooltip]);

  // link qua ext
  const handleTryItNow = () => {
    console.log('thieu truyen param show popup guid');
    window.open(
      types.URL.FACEBOOK + `&token=${localStorage.getItem("token") || null}&guidBombotGenAI=guidBombotGenAI`,
      "_blank"
    );
  }

  // Check if dashboard has data
  if (!dashboard || !dashboard.fanpage || !campaigns) {
    return <div> {t("noti.loading")}</div>; // Or another loading message
  }

  return (
    <Flex
      align="center"
      justify="space-between"
      flexWrap="wrap"
      row
      className={styles.box}
      style={{
        padding: "15px 0"
      }}
    >
      <Tour
        steps={tourConfig}
        isOpen={tourConfig != null}
        closeWithMask={false}
        lastStepNextButton={
          <Button size="small" className="btn--rounder" variant="contained">
            {t("common.btn.close")}
          </Button>
        }
        accentColor="#05ae34"
        onRequestClose={() => setTourConfig(null)}
      />

      <Flex
        style={{
          width: "100%",
          flexWrap: "wrap",
          gap: "20px"
        }}
        justify="space-between"
        align="stretch"
      >

        <CampaignChart
          id="camp_chart"
          style={{
            flex: "1 1 calc(50% - 20px)",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            backgroundColor: "#fff",
            minHeight: "250px"
          }}
        />
        
        <ScenarioChart
          id="scenario_chart"
          style={{
            flex: "1 1 calc(50% - 20px)",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            backgroundColor: "#fff",
            minHeight: "250px"
          }}
        />
      </Flex>

      <Flex
        style={{
          width: "100%",
          flexWrap: "wrap",
          gap: "20px",
          marginTop: "20px"
        }}
        justify="space-between"
        align="stretch"
      >
        <CustomerChart
          id="customer_chart"
          style={{
            flex: "1 1 calc(50% - 20px)",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            backgroundColor: "#fff",
            minHeight: "250px"
          }}
        />
        <MessageChart
          id="message_chart"
          style={{
            flex: "1 1 calc(50% - 20px)", // 50% width minus gap
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            backgroundColor: "#fff",
            minHeight: "250px"
          }}
        />
      </Flex>

      <Flex
        style={{
          width: "100%",
          flexWrap: "wrap",
          gap: "20px",
          marginTop: "20px"
        }}
        justify="space-between"
        align="stretch"
      >
        <GenAIChart
          id="genAI_chart"
          style={{
            flex: "1 1 calc(50% - 20px)",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            backgroundColor: "#fff",
            minHeight: "250px"
          }}
        />
        <div
          style={{
            backgroundColor: "black",
            flex: "1 1 calc(50% - 20px)",
            paddingTop: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            borderRadius: "12px",
            overflow: "hidden",
            minHeight: "250px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            color: "white"
          }}
        >
          <div>
            <div style={{ fontSize: "12px", color: "white" }}>
              Tính năng mới
            </div>
            <div
              style={{
                fontSize: "18px",
                color: "white",
                fontWeight: "bold",
                marginBottom: "20px"
              }}
            >
              BOMBOT GENAI
            </div>
            <ButtonComponent
              title={t("common.btn.try_it_now")}
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={handleTryItNow}
            />
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              overflow: "hidden",
              maxHeight: "calc(250px - 20px)"
            }}
          >
            <img
              src="/image/Group32.svg"
              alt="bombot-2"
              style={{
                backgroundColor: "black",
                paddingTop: "80px",
                maxWidth: "68%",
                maxHeight: "100%",
                objectFit: "contain"
              }}
            />
          </div>
        </div>
      </Flex>

      <CustomerOverview id="customer_stat_chart" />

      <FanpageChart
        id="page_cus_chart"
        style={{
          borderRadius: "12px",
          width: "100%",
          padding: "16px",
          marginBottom: "20px"
        }}
      />

      <BombotTagChart
        id="tag_bombot_cus_chart"
        style={{
          borderRadius: "12px",
          width: "100%",
          padding: "16px",
          marginBottom: "20px"
        }}
      />

      <FBTagChart
        id="tag_fb_cus_chart"
        style={{
          borderRadius: "12px",
          width: "100%",
          padding: "16px"
        }}
      />
    </Flex>
  );
};

export default DashboardChart;
