import React, { useEffect, useMemo, useRef, useState } from 'react'
import homeApi from '../../../../api/homeApi'
import { Card } from '@material-ui/core'
import { Doughnut } from 'react-chartjs-2'
import BaseDatePicker2 from '../../../../components/Form/BaseDatePicker2'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import Flex from '../../../../components/Flex'
import { useTranslation } from 'react-i18next'
import LegendItem from './LegendItem'
import { useCallback } from 'react'
import { debounce } from 'lodash'

const CampaignChart = ({ style, ...res }) => {
  const [data, setData] = useState(null);
  const { t } = useTranslation();

  const { control, watch } = useForm({
    defaultValues: {
      date: [moment().subtract(30, "days").toDate(), new Date()]
    }
  });

  // Đoạn code cũ gây duplicate api campaign/from=... bị gọi 2 lần
  // useEffect(() => {
  //   ;(async () => {
  //     try {
  //       const date = watch('date')
  //       const res = await homeApi.getCampaignStatistic({
  //         from: moment(date[0]).format('YYYY-MM-DD'),
  //         to: moment(date[1]).format('YYYY-MM-DD'),
  //       })
  //       setData(res)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })()
  // }, [watch('date')])

  // ======> FIX lỗi duplicate api
   const prevDate = useRef(watch("date")); // Lưu trữ giá trị date trước đó

   useEffect(() => {
     const currentDate = watch("date");
     if (currentDate !== prevDate.current) {
       prevDate.current = currentDate; // Cập nhật giá trị date mới
       (async () => {
         try {
          //  console.log("Calling API with date:", currentDate); // Log trước khi gọi API
           const res = await homeApi.getCampaignStatistic({
             from: moment(currentDate[0]).format("YYYY-MM-DD"),
             to: moment(currentDate[1]).format("YYYY-MM-DD")
           });
           setData(res);
         } catch (error) {
           console.log(error);
         }
       })();
     }
   }, [watch("date")]); 

  const totalCampaigns = data?.totalCampaigns || 0;

  const dataCampaigns = useMemo(() => {
    return {
      labels: [
        `${t("dashboard.campaign_stopped")}`, // Stopped
        `${t("dashboard.campaign_not_started")}`, // Not Started
        `${t("dashboard.campaign_completed")}`, // Completed
        `${t("dashboard.campaign_running")}` // Running
      ],
      datasets: [
        {
          data: [
            data?.totalCampaignCancel || 0, // Stopped
            data?.totalCampaignPending || 0, // Not Started
            data?.totalCampaignDone || 0, // Completed
            data?.totalCampaignRunning || 0 // Running
          ],
          backgroundColor: ["#ff6f91", "#ffb74d", "#81c784", "#64b5f6"],
          hoverBackgroundColor: ["#ff3d67", "#ff9800", "#4caf50", "#1e88e5"]
        }
      ]
    };
  }, [data, t]);

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const textOverlay = {
    id: "text-center",
    beforeDatasetsDraw(chart) {
      const { ctx } = chart;
      ctx.save();
      ctx.font = "600 14px sans-serif";
      ctx.fillStyle = "#000000de";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        `${totalCampaigns}`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
    }
  };

  const isEmpty = totalCampaigns === 0;

  return (
    <Card style={{ ...style, padding: "16px", borderRadius: "8px" }} {...res}>
      <Flex
        align="flex-start"
        style={{
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "16px"
        }}
      >
        <h3 style={{ margin: 0, fontSize: "18px", fontWeight: "600" }}>
          {t("dashboard.campaign")}
        </h3>

        <Flex
          align="flex-end"
          justify="flex-end"
          column
          style={{ textAlign: "right" }}
        >
          <BaseDatePicker2
            name={"date"}
            control={control}
            defaultValue={[null, null]}
          />
          <p
            style={{
              fontSize: "12px",
              fontStyle: "italic",
              marginTop: "4px",
              color: "#8f8f8f"
            }}
          >
            {t("dashboard.campaign_created_from_to")}
          </p>
        </Flex>
      </Flex>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        {isEmpty ? (
          <Flex
            align="center"
            justify="center"
            style={{ height: "150px", width: "100%" }}
          >
            <p style={{ fontSize: "16px", color: "#9095a0" }}>
              {t("dashboard.no_data")}
            </p>
          </Flex>
        ) : (
          <Flex
            align="center"
            justify="space-between"
            style={{ width: "100%" }}
          >
            <div style={{ width: "60%", paddingRight: "16px" }}>
              <Doughnut
                data={dataCampaigns}
                options={doughnutOptions}
                plugins={[textOverlay]}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
                width: "40%"
              }}
            >
              <LegendItem
                legendColor={dataCampaigns.datasets[0].backgroundColor[0]} // Stopped
                number={data?.totalCampaignCancel}
                percent={`${parseFloat(
                  (data?.totalCampaignCancel / totalCampaigns) * 100
                ).toFixed(2)} %`}
                title={t("dashboard.campaign_stopped")}
              />

              <LegendItem
                legendColor={dataCampaigns.datasets[0].backgroundColor[1]} // Not Started
                number={data?.totalCampaignPending}
                percent={`${parseFloat(
                  (data?.totalCampaignPending / totalCampaigns) * 100
                ).toFixed(2)} %`}
                title={t("dashboard.campaign_not_started")}
              />

              <LegendItem
                legendColor={dataCampaigns.datasets[0].backgroundColor[2]} // Completed
                number={data?.totalCampaignDone}
                percent={`${parseFloat(
                  (data?.totalCampaignDone / totalCampaigns) * 100
                ).toFixed(2)} %`}
                title={t("dashboard.campaign_completed")}
              />

              <LegendItem
                legendColor={dataCampaigns.datasets[0].backgroundColor[3]} // Running
                number={data?.totalCampaignRunning}
                percent={`${parseFloat(
                  (data?.totalCampaignRunning / totalCampaigns) * 100
                ).toFixed(2)} %`}
                title={t("dashboard.campaign_running")}
              />
            </div>
          </Flex>
        )}
      </div>
    </Card>
  );
}

export default CampaignChart
