import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IMAGES from "../../../../../src/images";

const MessengerButton = ({ name, point, handleClose }) => {
  const useStyles = makeStyles((theme) => ({
    root: {},

    //
    chatBox: {
      position: "fixed",
      bottom: "80px",
      right: "27px",
      zIndex: 100,

      "& div": {
        width: "54px",
        marginBottom: "8px",
        cursor: "pointer",
        "& .image": {
          "& img": {
            width: "100%",
            filter: "drop-shadow(0px 3px 6px rgb(0 0 0 / 0.25))"
          }
        }
      }
    },

    btnItem: {
      position: "relative",
      "& .text-content": {
        display: "none",
        width: "fit-content",
        padding: "10px",
        backgroundColor: "white",
        position: "absolute",
        right: "120%",
        top: "50%",
        borderRadius: "6px",
        boxShadow: "0 1.93465px 7.73859px rgba(0,0,0,.15)",
        transform: "translateY(-50%)"
      },
      "&:hover .text-content": {
        display: "block"
      }
    },
    closeBtn: {
      transition: "0.5s linear",
      "&:hover": {
        transform: "rotate(360deg)",
        transition: "0.5s linear"
      }
    }
  }));
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={classes.chatBox}>
      {/*  */}
      {isOpen && (
        <div isOpen={isOpen}>
          <div className={classes.btnItem}>
            <div className="image">
              <a href="https://hotro.bombot.vn" target="_blank ">
                <img src={IMAGES.WEV} alt="" />
              </a>
            </div>
            <div className="text-content">hotro.bombot.vn</div>
          </div>

          <div className={classes.btnItem}>
            <div className="image">
              <a href="tel:02871010134">
                <img src={IMAGES.CALL} />
              </a>
            </div>
            <div className="text-content">028.7101.0134</div>
          </div>

          <div className={classes.btnItem}>
            <div className="image">
              <a href="mailto:hotro@bombot.vn">
                <img src={IMAGES.BOXMAIL} />
              </a>
            </div>
            <div className="text-content">mailto:hotro@bombot.vn</div>
          </div>

          <div className={classes.btnItem}>
            <div className="image">
              <a
                target="_blank"
                href="https://www.facebook.com/messages/t/103634791744953"
              >
                <img src={IMAGES.FB} />
              </a>
            </div>
          </div>

          <div className={classes.closeBtn} onClick={() => onOpen()}>
            <div className="image">
              <img src={IMAGES.CLOSE} />
            </div>
          </div>
        </div>
      )}

      {!isOpen && (
        <div onClick={() => onOpen()}>
          <div className="image">
            <img src={IMAGES.CHAT} />
          </div>
        </div>
      )}
    </div>
  );
};
export default MessengerButton;
