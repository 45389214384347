import { useCallback, useEffect, useState } from "react";

import {
  ButtonGroup,
  IconButton,
  makeStyles,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  withStyles
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  RemoveCircleOutlined,
  Send
} from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import CampaignStatusLabel from "./CampaignStatusLabel";
import LoadingWrapper from "./LoadingWrapper";
import CoppyIcon from "./CoppyIcon";

import { showError, showSuccess } from "../stores/alert/slice.alert";
import moment from "moment";
import { useTranslation } from "react-i18next";
import scenarioApi from "../api/scenarioApi";
import ConfirmModal from "./ConfirmModal";
import { closeLoading, openLoading } from "../stores/loading/slice.loading";
import Flex from "./Flex";
import TimeLabel from "./TimeLabel";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FiltersContainer from "./FiltersContainer";
import ConfirmModal2 from "./ConfirmModal2";
import types from "../constants/types";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2),
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    borderRadius: "0px",
    overflow: "hidden",
    border: "1px solid rgb(222, 225, 230)"
  },
  collapseBtn: {
    minWidth: "50px",
    padding: "0 8px !important"
  },

  witdhOrd: {
    minWidth: "60px",
    padding: "0 8px !important"
  },

  witdhId: {
    minWidth: "170px",
    padding: "0 8px !important"
  },

  widthName: {
    minWidth: "160px",
    padding: "0 8px !important"
  },

  widthCreator: {
    minWidth: "160px",
    padding: "0 8px !important"
  },

  widthDate: {
    minWidth: "160px",
    padding: "0 8px !important"
  },

  widthStatisticBtn: {
    minWidth: "170px",
    padding: "0 8px !important"
  },

  widthStatistic: {
    minWidth: "180px",
    padding: "0 8px !important"
  },

  widthActions: {
    minWidth: "160px",
    padding: "0 8px !important"
  },

  widthStatus: {
    minWidth: "130px",
    padding: "0 8px !important"
  },

  p0: {
    padding: "0",
    margin: "0"
  },

  heightHeader: {
    height: "52px"
  },

  f600: {
    fontWeight: 600
  },

  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    padding: "10px 26px 16px 0"
  },

  actionBtnRemove: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#ff4d4f", // Red color for delete button
    fontWeight: 600,
    padding: "6px 12px",
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease", // Transition for smooth effect
    "&:hover": {
      backgroundColor: "rgba(255, 77, 79, 0.1)" // Light red background on hover
    }
  },

  actionBtnDetail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "rgb(0, 123, 255)", // Red color for delete button
    fontWeight: 600,
    padding: "6px 10px",
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease", // Transition for smooth effect
    "&:hover": {
      backgroundColor: "rgba(33, 150, 243, 0.2)" // Light red background on hover
    }
  },

  actionSend: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#52c41a", // Màu xanh lá cho nút gửi tin nhắn
    fontWeight: 600,
    padding: "8px 12px",
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease", // Hiệu ứng chuyển mượt
    "&:hover": {
      backgroundColor: "rgba(82, 196, 26, 0.1)" // Nền xanh lá nhạt khi hover
    }
  },

  actionAdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#636ae8", // Màu xanh lá cho nút gửi tin nhắn
    fontWeight: 600,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease", // Hiệu ứng chuyển mượt
    "&:hover": {
      backgroundColor: "rgba(99, 106, 232, 0.1)" // Nền xanh lá nhạt khi hover

      // backgroundColor: 'rgba(82, 196, 26, 0.1)', // Nền xanh lá nhạt khi hover
    }
  },

  actionBtnSearch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#007bff", // Blue color similar to "Bombot Tags" text
    fontWeight: 600,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease", // Transition for smooth effect
    backgroundColor: "rgb(230, 243, 255, 0.5)",
    "&:hover": {
      backgroundColor: "rgb(230, 243, 255, 0.8)" // Light blue background color
    },
    border: "1px solid #a3c1dd"
  },

  actionIcon: {
    fontSize: "14px"
  },
  actionTitle: {
    margin: 0,
    fontSize: "10px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  rowItem: {
    cursor: "pointer"
  }
}));

const sortFields = {
  CREATED_AT: "createdAt",
  TIME_SCHEDULE: "timeSchedule",
  ALPHABET: "alphabet"
};

const sortOrd = {
  ASC: "asc",
  DESC: "desc"
};

const dateRange = {
  LAST_7D: [
    moment().clone().subtract(7, "days").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD")
  ],
  NEXT_24H: [
    moment().format("YYYY-MM-DD"),
    moment().clone().add(1, "days").format("YYYY-MM-DD")
  ],
  NEXT_3D: [
    moment().format("YYYY-MM-DD"),
    moment().clone().add(3, "days").format("YYYY-MM-DD")
  ],
  NEXT_7D: [
    moment().format("YYYY-MM-DD"),
    moment().clone().add(7, "days").format("YYYY-MM-DD")
  ],
  NEXT_14D: [
    moment().format("YYYY-MM-DD"),
    moment().clone().add(14, "days").format("YYYY-MM-DD")
  ],
  NEXT_30D: [
    moment().format("YYYY-MM-DD"),
    moment().clone().add(30, "days").format("YYYY-MM-DD")
  ]
};

export const initSort = {
  [sortFields.CREATED_AT]: "asc",
  [sortFields.TIME_SCHEDULE]: "asc",
  [sortFields.ALPHABET]: "asc"
};

const defaultSizeGetScenario = 10;

const ScenarioManagement = (isPagination) => {
  const classes = useStyles();

  const history = useHistory();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isCopiedId, setIsCopiedId] = useState(false);
  const [removingScenario, setRemovingScenario] = useState(null);
  const [isLoadingList, setIsLoadingList] = useState(false);

  const [page, setPage] = useState(0);
  const [subScenarios, setCollapsedScenarios] = useState(null);
  const [scenarioList, setScenarioList] = useState(null);

  const [selectedFilter, setSelectedFilter] = useState("NEXT_7D");

  const handleFilterChange = (filter) => {
    if (filter === "ALL") {
      history.push({
        pathname: `/quan-ly-kich-ban`
      });
    }
    setSelectedFilter(filter);
    // Gọi hàm để tải lại danh sách kịch bản với bộ lọc mới
    // Ví dụ: loadScenarioList(filter);
  };

  useEffect(() => {
    (async () => {
      setIsLoadingList(true);

      const params = {
        page: page,
        size: 10,
        limit: 30,
        search: `scheduleTime=bw=${dateRange[selectedFilter].join(",")}`
      };

      const res = await getScenarioList(params);

      setScenarioList(res);

      setIsLoadingList(false);
    })();
  }, [selectedFilter, removingScenario]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setIsLoadingList(true);

    let sort = ``;
    let search = "";

    const params = {
      page: newPage,
      size: 10,
      limit: 30,
      search: `search=scheduleTime=bw=${dateRange["NEXT_3D"]},${dateRange["NEXT_3D"]}`
    };

    const res = await getScenarioList(params);

    setScenarioList(res);

    setIsLoadingList(false);
  };

  const handleDeleteScenario = async (scenarioId, campaignId) => {
    if (!scenarioId || !campaignId) return;
    try {
      dispatch(openLoading());
      const payload = {
        id: campaignId,
        scenarioId
      };
      // await dispatch(deleteScenario(payload))

      await scenarioApi.deleteScenario(payload);

      const params = {
        page: 0,
        size: 10,
        limit: 30,
        search: `scheduleTime=bw=${dateRange[selectedFilter].join(",")}`
      };

      const res = await getScenarioList(params);

      setScenarioList(res);

      dispatch(showSuccess("Delete Scenario Successfully"));
    } catch (error) {
      console.log("error ==>", error);
      dispatch(showError(error.message));
    } finally {
      dispatch(closeLoading());
    }
  };

  const getScenarioList = async (params) => {
    return scenarioApi.get(params);
  };

  const onViewScenario = useCallback((campaignId, scenarioId, pageId) => {
    window.open(
      `${types.URL.FACEBOOK}&token=${
        localStorage.getItem("token") || null
      }&campaign=${campaignId}&pageId=${pageId}&scenario=${scenarioId}`,
      "_blank"
    );
  }, []);

  return (
    <div>
      <div
        id="scenario_list"
        style={{
          marginBottom: "16px"
        }}
      >
        <FiltersContainer
          selectedFilter={selectedFilter}
          handleFilterChange={handleFilterChange}
          options={[
            {
              label: t("common.btn.time_periods.7_days_ago"),
              value: "LAST_7D",
              color: "#007bff"
            },
            {
              label: t("common.btn.time_periods.24h_next"),
              value: "NEXT_24H",
              color: "#007bff"
            },
            {
              label: t("common.btn.time_periods.7_days_next"),
              value: "NEXT_7D",
              color: "#007bff"
            },
            {
              label: t("common.btn.time_periods.14_days_next"),
              value: "NEXT_14D",
              color: "#007bff"
            },
            {
              label: t("common.btn.time_periods.30_days_next"),
              value: "NEXT_30D",
              color: "#007bff"
            },
            {
              label: t("common.btn.time_periods.all"),
              value: "ALL",
              color: "#007bff"
            }
          ]}
        />

        <LoadingWrapper isOpen={isLoadingList} style={{ width: "100%" }}>
          {/* <ScenarioFilterContainer /> */}

          <Paper className={classes.paper}>
            <TableContainer
              component={Paper}
              style={{
                position: "relative",
                maxHeight: "400px",
                borderRadius: 0,
                overflow: "hidden"
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    className={[classes.heightHeader]}
                    style={{
                      width: "max-content"
                    }}
                  >
                    <TableCell
                      className={[classes.collapseBtn, classes.p0]}
                    ></TableCell>

                    <TableCell
                      align="center"
                      className={[classes.witdhOrd, classes.p0, classes.f600]}
                    >
                      <div>{t("overall.lastest_scenario.number")}</div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[classes.witdhId, classes.p0, classes.f600]}
                    >
                      <div>{t("overall.lastest_scenario.code")}</div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[classes.widthName, classes.p0, classes.f600]}
                    >
                      <div>{t("overall.lastest_scenario.name")}</div>
                    </TableCell>
                    {/* <TableCell
                  align="center"
                  className={[classes.widthCreator, classes.p0, classes.f600]}
                >
                  <div>Creator</div>
                </TableCell> */}
                    <TableCell
                      align="center"
                      className={[classes.widthDate, classes.p0, classes.f600]}
                    >
                      <div>{t("overall.lastest_scenario.created_date")}</div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[
                        classes.widthStatisticBtn,
                        classes.p0,
                        classes.f600
                      ]}
                    >
                      <div>{t("overall.lastest_scenario.customers")}</div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[
                        classes.widthStatistic,
                        classes.p0,
                        classes.f600
                      ]}
                    >
                      <div>{t("overall.lastest_scenario.cus_success")}</div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[
                        classes.widthStatistic,
                        classes.p0,
                        classes.f600
                      ]}
                    >
                      <div>{t("overall.lastest_scenario.cus_failed")}</div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[
                        classes.widthStatistic,
                        classes.p0,
                        classes.f600
                      ]}
                    >
                      <div>{t("overall.lastest_scenario.cus_not_send")}</div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[
                        classes.widthStatistic,
                        classes.p0,
                        classes.f600
                      ]}
                    >
                      <div>{t("overall.lastest_scenario.messages")}</div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[
                        classes.widthStatistic,
                        classes.p0,
                        classes.f600
                      ]}
                    >
                      <div>
                        {t("overall.lastest_scenario.messages_success")}
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[
                        classes.widthStatistic,
                        classes.p0,
                        classes.f600
                      ]}
                    >
                      <div>{t("overall.lastest_scenario.messages_failed")}</div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[
                        classes.widthStatistic,
                        classes.p0,
                        classes.f600
                      ]}
                    >
                      <div>
                        {t("overall.lastest_scenario.messages_not_send")}
                      </div>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[
                        classes.widthStatistic,
                        classes.p0,
                        classes.f600
                      ]}
                    >
                      <div>
                        {t("overall.lastest_scenario.message_sent_count")}
                      </div>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[
                        classes.widthStatus,
                        classes.p0,
                        classes.f600
                      ]}
                    >
                      <div>{t("overall.lastest_scenario.status")}</div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[
                        classes.widthStatus,
                        classes.p0,
                        classes.f600
                      ]}
                    >
                      <div>{t("overall.lastest_scenario.schedule_send")}</div>
                    </TableCell>

                    {/* {scenarioList?.content?.length > 0 && (
                    <CellSticky
                      align="center"
                      className={[
                        classes.widthActions,
                        classes.pRow,
                        classes.f600
                      ]}
                      style={{
                        borderLeft: "0.2px solid rgb(246, 239, 239)",
                        boxShadow: "none",
                        backgroundColor: "#fafafa",
                        position: "sticky",
                        right: 0,
                        zIndex: 99
                      }}
                    >
                      <div>{t("overall.lastest_scenario.actions")}</div>
                    </CellSticky>
                  )} */}
                  </TableRow>
                </TableHead>

                {scenarioList?.content?.length > 0 && (
                  <TableBody>
                    {scenarioList?.content?.map((row, index) => (
                      <>
                        <TableRow
                          key={row.id}
                          onClick={() =>
                            onViewScenario(row.campaignId, row.id, row.pageId)
                          }
                          className={[classes.heightHeader, classes.rowItem]}
                        >
                          <TableCell
                            className={[classes.collapseBtn, classes.p0]}
                            style={{
                              textAlign: "center"
                            }}
                          >
                            {row.totalSubScenario ? (
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                // onClick={() => {
                                //   if (
                                //     subScenarios?.length &&
                                //     subScenarios[0]?.scenarioRefId === row.id
                                //   ) {
                                //     handleShowSubScenarios(null)
                                //   } else {
                                //     handleShowSubScenarios(row.id)
                                //   }
                                // }}
                              >
                                {subScenarios?.length &&
                                subScenarios[0]?.scenarioRefId === row.id ? (
                                  <KeyboardArrowDown />
                                ) : (
                                  <KeyboardArrowUp />
                                )}
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.witdhOrd, classes.p0]}
                          >
                            <div>
                              {scenarioList?.pageable?.pageNumber *
                                defaultSizeGetScenario +
                                index +
                                1}
                            </div>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.witdhId, classes.p0]}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                              }}
                            >
                              <Tooltip title={row.id} aria-label="add">
                                <div
                                  style={{
                                    maxWidth: "120px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}
                                >
                                  {row.id}
                                </div>
                              </Tooltip>
                              <Tooltip
                                title={isCopiedId ? "Copied!" : "Copy"}
                                aria-label="add"
                              >
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(row.id);
                                    setIsCopiedId(true);
                                    setTimeout(() => {
                                      setIsCopiedId(false);
                                    }, 1000);
                                  }}
                                  size="small"
                                  style={{ marginLeft: "14px" }}
                                >
                                  <CoppyIcon height={"20px"} width={"20px"} />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthName, classes.p0]}
                          >
                            {row.name ? (
                              <div>{row.name}</div>
                            ) : (
                              <p
                                style={{
                                  fontStyle: "italic",
                                  fontSize: "12px"
                                }}
                              >
                                {"_"}
                              </p>
                            )}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthDate, classes.p0]}
                          >
                            {row.createdAt ? (
                              <div>
                                {moment(row.createdAt).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </div>
                            ) : (
                              <p
                                style={{
                                  fontStyle: "italic",
                                  fontSize: "12px"
                                }}
                              >
                                {"_"}
                              </p>
                            )}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthStatisticBtn, classes.p0]}
                          >
                            {row.totalCus ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "14px"
                                  }}
                                >
                                  {row.totalCus}
                                </p>
                              </div>
                            ) : (
                              <p
                                style={{
                                  fontStyle: "italic",
                                  fontSize: "12px"
                                }}
                              >
                                {"_"}
                              </p>
                            )}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthStatistic, classes.p0]}
                          >
                            <div>{row.customerSuccess}</div>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthStatistic, classes.p0]}
                          >
                            <div>{row.customerFailed}</div>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthStatistic, classes.p0]}
                          >
                            <div>{row.customerNotSend}</div>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthStatistic, classes.p0]}
                          >
                            <div>{row.totalMes}</div>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthStatistic, classes.p0]}
                          >
                            <div>{row.messageSuccess}</div>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthStatistic, classes.p0]}
                          >
                            <div>{row.messageFailed}</div>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthStatistic, classes.p0]}
                          >
                            <div>{row.messageNotSend}</div>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthStatus, classes.p0]}
                          >
                            <div>{row.totalClickSent}</div>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthStatus, classes.p0]}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              <CampaignStatusLabel
                                size="small"
                                type={row?.status}
                                message={t(`campaign_status.${row?.status}`)}
                              />
                            </div>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={[classes.widthStatus, classes.p0]}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              <TimeLabel
                                sendTime={
                                  row?.schedule?.scheduleTime
                                    ? parseInt(row?.schedule?.scheduleTime)
                                    : null
                                }
                              />
                            </div>
                          </TableCell>

                          {/* <TableCell
                          align="center"
                          className={[classes.widthActions, classes.p0]}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderLeft: "0.2px solid rgb(246, 239, 239)",
                            boxShadow: "none",
                            backgroundColor: "#fafafa",
                            position: "sticky",
                            right: 0,
                            top: 0,
                            zIndex: 2,
                            height: "52px"
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "8px 0",
                              width: "100%"
                            }}
                          >
                            <Tooltip
                              title={t("overall.lastest_scenario.see_detail")}
                            >
                              <Button
                                style={{
                                  background: "none",
                                  padding: 0,
                                  minWidth: "auto"
                                }}
                                onClick={() =>
                                  onViewScenario(
                                    row.campaignId,
                                    row.id,
                                    row.pageId
                                  )
                                }
                              >
                                <div className={classes.actionBtnDetail}>
                                  <p className={classes.actionTitle}>
                                    {t("overall.lastest_scenario.see_detail")}
                                  </p>
                                </div>
                              </Button>
                            </Tooltip>
                          </div>
                        </TableCell> */}
                        </TableRow>

                        {subScenarios?.length === 0 &&
                        subScenarios[0]?.scenarioRefId === row.id ? (
                          subScenarios?.reverse().map((subRow, subIndex) => {
                            return (
                              <TableRow
                                key={row.id}
                                className={[
                                  classes.heightHeader,
                                  classes.rowItem
                                ]}
                              >
                                <TableCell></TableCell>

                                <TableCell
                                  align="center"
                                  className={[classes.witdhOrd, classes.p0]}
                                ></TableCell>

                                <TableCell
                                  align="center"
                                  className={[classes.witdhId, classes.p0]}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <Tooltip title={subRow.id} aria-label="add">
                                      <div
                                        style={{
                                          maxWidth: "120px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis"
                                        }}
                                      >
                                        {subRow.id}
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      title={isCopiedId ? "Copied!" : "Copy"}
                                      aria-label="add"
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigator.clipboard.writeText(
                                            subRow.id
                                          );
                                          setIsCopiedId(true);
                                          setTimeout(() => {
                                            setIsCopiedId(false);
                                          }, 1000);
                                        }}
                                        size="small"
                                        style={{ marginLeft: "14px" }}
                                      >
                                        <CoppyIcon
                                          height={"20px"}
                                          width={"20px"}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[classes.widthName, classes.p0]}
                                >
                                  {subRow.name ? (
                                    <div>{subRow.name}</div>
                                  ) : (
                                    <p
                                      style={{
                                        fontStyle: "italic",
                                        fontSize: "12px"
                                      }}
                                    >
                                      {"_"}
                                    </p>
                                  )}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[classes.widthDate, classes.p0]}
                                >
                                  {subRow.createdAt ? (
                                    <div>
                                      {moment(subRow.createdAt).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </div>
                                  ) : (
                                    <p
                                      style={{
                                        fontStyle: "italic",
                                        fontSize: "12px"
                                      }}
                                    >
                                      {"_"}
                                    </p>
                                  )}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[
                                    classes.widthStatisticBtn,
                                    classes.p0
                                  ]}
                                >
                                  {subRow.totalCus ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "14px"
                                        }}
                                      >
                                        {subRow.totalCus}
                                      </p>
                                      {/* <IconButton
                                          size="small"
                                          style={{ marginLeft: '14px' }}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            setScenarioCustomerSelected(subRow.id)
                                          }}
                                        >
                                          <PeopleOutline height={'20px'} width={'20px'} />
                                        </IconButton> */}
                                    </div>
                                  ) : (
                                    <p
                                      style={{
                                        fontStyle: "italic",
                                        fontSize: "12px"
                                      }}
                                    >
                                      {"_"}
                                    </p>
                                  )}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[
                                    classes.widthStatistic,
                                    classes.p0
                                  ]}
                                >
                                  <div>{subRow.customerSuccess}</div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[
                                    classes.widthStatistic,
                                    classes.p0
                                  ]}
                                >
                                  <div>{subRow.customerFailed}</div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[
                                    classes.widthStatistic,
                                    classes.p0
                                  ]}
                                >
                                  <div>{subRow.customerNotSend}</div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[
                                    classes.widthStatistic,
                                    classes.p0
                                  ]}
                                >
                                  <div>{subRow.totalMes}</div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[
                                    classes.widthStatistic,
                                    classes.p0
                                  ]}
                                >
                                  <div>{subRow.messageSuccess}</div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[
                                    classes.widthStatistic,
                                    classes.p0
                                  ]}
                                >
                                  <div>{subRow.messageFailed}</div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[
                                    classes.widthStatistic,
                                    classes.p0
                                  ]}
                                >
                                  <div>{subRow.messageNotSend}</div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[classes.widthStatus, classes.p0]}
                                >
                                  <div>{subRow.totalClickSent}</div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[classes.widthStatus, classes.p0]}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <CampaignStatusLabel
                                      size="small"
                                      type={subRow?.status}
                                      message={t(
                                        `campaign_status.${subRow?.status}`
                                      )}
                                    />
                                  </div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[classes.widthStatus, classes.p0]}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <TimeLabel
                                      sendTime={
                                        subRow?.schedule?.scheduleTime
                                          ? parseInt(
                                              subRow?.schedule?.scheduleTime
                                            )
                                          : null
                                      }
                                    />
                                  </div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={[classes.widthActions, classes.p0]}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderLeft:
                                      "0.2px solid rgb(246, 239, 239)",
                                    boxShadow: "none",
                                    backgroundColor: "#fafafa",
                                    position: "sticky",
                                    right: 0,
                                    top: 0,
                                    zIndex: 2,
                                    height: "52px"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      padding: "8px 0",
                                      width: "100%"
                                    }}
                                  >
                                    {/* <Tooltip
                                    title={t('overall.lastest_scenario.delete')}
                                  >
                                    <Button
                                      style={{
                                        background: 'none',
                                        padding: 0,
                                        minWidth: 'auto',
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        setRemovingScenario(subRow)
                                      }}
                                    >
                                      <div className={classes.actionBtnRemove}>
                                        <RemoveCircleOutlined
                                          className={classes.actionIcon}
                                        />
                                      </div>
                                    </Button>
                                  </Tooltip> */}

                                    <Tooltip
                                      title={t(
                                        "overall.lastest_scenario.see_detail"
                                      )}
                                    >
                                      <Button
                                        style={{
                                          background: "none",
                                          padding: 0,
                                          minWidth: "auto"
                                        }}
                                        onClick={() =>
                                          onViewScenario(
                                            row.campaignId,
                                            row.id,
                                            row.pageId
                                          )
                                        }
                                      >
                                        <div
                                          className={classes.actionBtnDetail}
                                        >
                                          <p className={classes.actionTitle}>
                                            {t("campaign_dashboard.detail")}
                                          </p>
                                        </div>
                                      </Button>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <> </>
                        )}
                      </>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            {scenarioList?.content?.length === 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                  minHeight: "69px",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    width: "100%",
                    color: "rgba(0,0,0,.5)",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  {t("update.no_data")}
                </p>
              </div>
            )}

          </Paper>
        </LoadingWrapper>
        {/* {isPagination && scenarioList?.content?.length > 0 && (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Flex
                align="center"
                justify="space-between"
                style={{
                  width: "100%"
                }}
              >
                <Pagination
                  style={{
                    marginRight: "20px",
                    marginBottom: "40px"
                  }}
                  count={Math.ceil(
                    scenarioList?.numberOfElements / defaultSizeGetScenario
                  )}
                  page={page + 1}
                  onChange={(event, newPage) =>
                    handleChangePage(event, newPage - 1)
                  }
                  disabled={scenarioList?.numberOfElements === 0}
                  color="secondary"
                />
                <Flex></Flex>
              </Flex>
            </div>
          </>
        )} */}

        <ConfirmModal2
          onClose={() => {
            setRemovingScenario(null);
          }}
          onConfirm={() => {
            handleDeleteScenario(
              removingScenario?.id,
              removingScenario?.campaignId
            );
            setRemovingScenario(null);
          }}
          title={`Confirm scenario deletion`}
          message={`Are you sure you want to delete the scenario ${removingScenario?.name}?`}
          open={!_.isNull(removingScenario)}
        />
        {/* {isOpenFilter && (
        <ScenarioFilterForm
          sortOrder={sortOrder}
          isOpen={isOpenFilter}
          handleClose={() => setIsOpenFilter(false)}
        />
      )} */}
      </div>
    </div>
  );
};

const CellSticky = styled(TableCell)`
  position: sticky;
  background-color: #fff;
  right: 0;
  top: 0;
  z-index: 2;
  box-shadow: -15px 0px 17px -7px #f0eded;
`;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "transparent",
    maxWidth: "none"
  }
}))(Tooltip);

export default ScenarioManagement;
