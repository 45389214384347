import { Button, IconButton, TextField, Tooltip } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import { makeStyles, styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { CheckOutlined, CheckRounded, SortRounded } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import cn from "classnames";
import Flex from "components/Flex";
import { CheckIcon, ChevronDown, FilterIcon } from "lucide-react";
import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import types from "../constants/types";
import ButtonComponent from "./Button";
import CampaignStatusLabel from "views/Scenario/components/CampaignStatusLabel";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2),
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    borderRadius: "0px",
    overflow: "hidden",
    border: "1px solid rgb(222, 225, 230)"
  },
  table: {
    minWidth: "100%"
  },
  tableHead: {
    backgroundColor: "#F4F4F4FF"
  },
  tableHeadCell: {
    fontWeight: "bold"
  },
  tableCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "12px 16px",
    minWidth: "150px"
  },
  ordNumberCell: {
    width: "80px",
    minWidth: "80px"
  },
  idCell: {
    width: "180px",
    minWidth: "180px"
  },
  nameCell: {
    width: "250px",
    minWidth: "250px"
  },
  pageNameCell: {
    width: "200px",
    minWidth: "200px"
  },
  creatorCell: {
    width: "180px",
    minWidth: "180px"
  },
  dateTimeCell: {
    width: "160px",
    minWidth: "160px"
  },
  statusCell: {
    width: "140px",
    minWidth: "140px",
    "& span": {
      padding: "6px 12px",
      borderRadius: "4px",
      fontWeight: "500",
      display: "inline-block",
      width: "100%",
      textAlign: "center"
    }
  },
  numberCell: {
    width: "120px",
    minWidth: "120px"
  },
  copyButton: {
    padding: 4,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  wrapperSearch: {
    width: 500,
    marginBottom: theme.spacing(2)
  },
  noData: {
    color: "rgba(0,0,0,.5)",
    fontWeight: "bold",
    textAlign: "center",
    padding: theme.spacing(3)
  },
  clickableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  actionCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: "0.2px solid rgb(246, 239, 239)",
    backgroundColor: "#fafafa",
    position: "sticky",
    right: 0,
    top: 0,
    zIndex: 1
  },
  actionButton: {
    background: "none",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(0, 123, 255)",
    fontWeight: 600,
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "rgba(33, 150, 243, 0.2)"
    }
  },
  actionBtnDetail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 10px",
    borderRadius: "8px"
  },
  actionTitle: {
    margin: 0,
    fontSize: "10px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonSearch: {
    backgroundColor: "#05AE34",
    color: "white",
    padding: "6px 16px",
    "&:hover": {
      backgroundColor: "#048A2D"
    }
  },
  textField: {
    backgroundColor: "#ffffff",
    "& .MuiOutlinedInput-root": {}
  },
  p0: {
    padding: "0",
    margin: "0"
  },
  witdhOrd: {
    minWidth: "140px",
    padding: "0 8px !important"
  },
  f600: {
    fontWeight: 600
  },
  heightHeader: {
    height: "52px"
  },
  rowItem: {
    cursor: "pointer"
  }
}));

const headCells = [
  {
    id: "ord_number",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.ord_number"
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.campaign_id"
  },
  {
    id: "name",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.campaign_name"
  },
  {
    id: "fanpage",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.page_name"
  },
  {
    id: "author",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.creator"
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.create_time"
  },
  {
    id: "status",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.status"
  },
  {
    id: "timeStart",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.start_time"
  },

  {
    id: "totalScenario",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.scenario"
  },

  {
    id: "numberCus",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.customer"
  },

  {
    id: "numberCusSuccess",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.successful_customer"
  },

  {
    id: "numberCusFailed",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.failed_customer"
  },

  {
    id: "numberCusNotSend",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.pending_customer"
  },

  {
    id: "totalMess",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.message"
  },

  {
    id: "messSuccss",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.successful_message"
  },
  {
    id: "messFail",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.failed_message"
  },
  {
    id: "messNotSend",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.pending_message"
  }
];

function EnhancedTableHead(props) {
  const { classes, t } = props;

  return (
    <TableHead
      className={classes.tableHead}
      style={{
        position: "sticky",
        top: -1,
        borderBottom: "1px solid #D1D5DB",
        zIndex: 1
      }}
    >
      <TableRow className={[classes.heightHeader, classes.rowItem]}>
        {headCells.map((headCell) => {
          let cellClass = classes.tableCell;
          switch (headCell.id) {
            case "ord_number":
              cellClass = cn(classes.tableCell, classes.ordNumberCell);
              break;
            case "id":
              cellClass = cn(classes.tableCell, classes.idCell);
              break;
            case "name":
              cellClass = cn(classes.tableCell, classes.nameCell);
              break;
            case "fanpage":
              cellClass = cn(classes.tableCell, classes.pageNameCell);
              break;
            case "author":
              cellClass = cn(classes.tableCell, classes.creatorCell);
              break;
            case "createdAt":
            case "timeStart":
              cellClass = cn(classes.tableCell, classes.dateTimeCell);
              break;
            case "status":
              cellClass = cn(classes.tableCell, classes.statusCell);
              break;
            case "numberCus":
            case "totalMess":
            case "messSuccss":
            case "messFail":
            case "messNotSend":
            case "numberCusSuccess":
            case "numberCusFailed":
            case "numberCusNotSend":
            case "totalScenario":
              cellClass = cn(classes.tableCell, classes.numberCell);
              break;
            default:
              break;
          }

          return (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              // className={cn(cellClass, classes.tableHeadCell)}
              align="center"
              className={[classes.witdhOrd, classes.p0, classes.f600]}
              style={{
                whiteSpace: "nowrap", 
                overflow: "hidden", 
                textOverflow: "ellipsis" 
              }}
            >
              <TableSortLabel hideSortIcon={true}>
                {t(headCell.label)}
              </TableSortLabel>
            </TableCell>
          );
        })}
        {/* <CellSticky
          align="center"
          className={[classes.widthActions, classes.pRow, classes.f600]}
          style={{
            borderLeft: '0.2px solid rgb(246, 239, 239)',
            boxShadow: 'none',
            backgroundColor: '#fafafa',
            position: 'sticky',
            right: 0,
            zIndex: 1,
          }}
        >
          <div>{t('common.btn.action')}</div>
        </CellSticky> */}
      </TableRow>
    </TableHead>
  );
}

const CellSticky = styled(TableCell)`
  position: sticky;
  background-color: #fff;
  right: 0;
  top: 0;
  z-index: 2;
  box-shadow: -15px 0px 17px -7px #f0eded;
`;

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default function ListCampaign(props) {
  const {
    data,
    handleGetCampaign,
    getCampaign,
    isPagination,
    isSearch,
    isShowFilter
  } = props;
  const rows = (data && data.content) || [];
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const dataStatus = [
    { id: 0, name: "init", value: t("campaign_management.status.init") },
    { id: 1, name: "running", value: t("campaign_management.status.running") },
    { id: 2, name: "done", value: t("campaign_management.status.done") },
    { id: 3, name: "paused", value: t("campaign_management.status.paused") }
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(types.PAGINATION.SIZE);
  const [search, setSearch] = useState("");
  const [copied, setCopied] = useState(false);

  const [sortNameOrder, setSortNameOrder] = useState("");
  const [tempSortNameOrder, setTempSortNameOrder] = useState("");

  const [sortDateRangeOrder, setSortDateRangeOrder] =
    useState("createdAt,desc");
  const [tempSortDateRangeOrder, setTempSortDateRangeOrder] =
    useState("createdAt,desc");

  const [selectedStatus, setSelectedStatus] = useState(
    dataStatus.map((s) => s.name)
  );
  const [tempSelectedStatus, setTempSelectedStatus] = useState(
    dataStatus.map((s) => s.name)
  );
  const [isOpen, setIsOpen] = useState(false);

  const [openSortModal, setOpenSortModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const sortModalRef = useRef(null);
  const filterModalRef = useRef(null);
  const filterPopupRef = useRef(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");

  const [searchQuery, setSearchQuery] = useState("");

  const handleToggleSortModal = () => {
    setOpenSortModal(!openSortModal);
  };

  const handleToggleFilterModal = () => {
    setOpenFilterModal(!openFilterModal);
  };

  function useOutsideAlerter(ref, ...handleFuncs) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleFuncs.forEach((func) => func());
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, handleFuncs]);
  }

  useOutsideAlerter(
    filterModalRef,
    () => setOpenFilterModal(false),
    () => setIsOpen(false)
  );
  useOutsideAlerter(sortModalRef, () => setOpenSortModal(false));
  useOutsideAlerter(filterPopupRef, () => setIsOpen(false));

  const handleSortName = (order) => {
    setSortNameOrder(order);
  };

  const handleSortDateRange = (order) => {
    setSortDateRangeOrder(order);
  };

  const handleCopy = useCallback((id) => {
    navigator.clipboard.writeText(id).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, []);

  useEffect(() => {
    handleSubmitApply(search);
  }, []);

  useEffect(() => {
    if (openSortModal) {
      setSortNameOrder(tempSortNameOrder);
      setSortDateRangeOrder(tempSortDateRangeOrder);
    }
  }, [openSortModal]);

  useEffect(() => {
    if (openFilterModal) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setSelectedStatus(tempSelectedStatus);
    }
  }, [openFilterModal]);

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    handleSubmitApply(search, 0, newRowsPerPage);
  };

  const handleChange = (event, value) => {
    setPage(value - 1);
    handleSubmitApply(search, value - 1);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSubmitSearch = (search) => {
    handleSubmitApply();
  };

  const handleClickFilter = () => {
    handleSubmitApply();
  };

  const createParams = (
    selectedStatus,
    startDate,
    endDate,
    sortNameOrder,
    sortDateRangeOrder,
    search,
    page,
    size
  ) => {
    let searchParams = "";

    if (
      selectedStatus.length > 0 &&
      selectedStatus.length < dataStatus.length
    ) {
      searchParams += `status=in=${selectedStatus.join(",")};`;
    }

    if (startDate && endDate) {
      searchParams += `createdAt=bw=${startDate},${endDate};`;
    }
    if (search && typeof search === "string" && search.trim() !== "") {
      searchParams += `name|pageNameText==${search};`;
    }

    console.log("searchParams ==>", searchParams);

    const sortOptions = [];
    if (sortDateRangeOrder) {
      sortOptions.push(sortDateRangeOrder);
    }
    if (sortNameOrder) {
      sortOptions.push(sortNameOrder);
    }

    const sort =
      sortOptions.length > 0 ? sortOptions.join(";") : "createdAt,desc";

    const payload = {
      page: page,
      search: searchParams,
      size: size,
      sort: sort
    };

    return payload;
  };

  const onViewCampaign = useCallback((campaignId, pageId) => {
    window.open(
      `${types.URL.FACEBOOK}&token=${
        localStorage.getItem("token") || null
      }&campaign=${campaignId}&pageId=${pageId}`,
      "_blank"
    );
  }, []);

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmitApply();
    }
  };

  const handleSelect = (status) => {
    if (status === "all") {
      if (selectedStatus.length === dataStatus.length) {
        return;
      } else {
        setSelectedStatus(dataStatus.map((s) => s.name));
      }
    } else {
      setSelectedStatus((prev) => {
        if (prev.includes(status)) {
          const newSelected = prev.filter((s) => s !== status);
          if (newSelected.length === 0) {
            return [];
          }
          return newSelected;
        } else {
          const newSelected = [...prev, status];
          if (newSelected.length === dataStatus.length) {
            return newSelected;
          }
          return newSelected;
        }
      });
    }
  };

  const handleResetAllSort = () => {
    setSortNameOrder("");
    setSortDateRangeOrder("createdAt,desc");
    handleSubmitApply(
      search,
      0,
      rowsPerPage,
      startDate,
      endDate,
      selectedStatus,
      "",
      "createdAt,desc"
    );
    setSortNameOrder("");
    setTempSortNameOrder("");
    setSortDateRangeOrder("createdAt,desc");
    setTempSortDateRangeOrder("createdAt,desc");
  };

  const handleResetDateRange = () => {
    setStartDate("");
    setEndDate("");
  };

  const handleResetStatus = () => {
    setSelectedStatus(dataStatus.map((status) => status.name));
  };

  const handleResetAllFilter = () => {
    setStartDate("");
    setEndDate("");
    setSelectedStatus(dataStatus.map((s) => s.name));
    handleSubmitApply(
      search,
      0,
      rowsPerPage,
      "",
      "",
      dataStatus.map((s) => s.name),
      sortNameOrder,
      sortDateRangeOrder
    );
    setTempStartDate("");
    setTempEndDate("");
    setTempSelectedStatus(dataStatus.map((s) => s.name));
  };

  const handleSubmitApply = (
    searchVal = search,
    pageVal = page,
    sizeVal = rowsPerPage,
    startdate = startDate,
    enddate = endDate,
    selectedstatus = selectedStatus,
    sortnameorder = sortNameOrder,
    sortdaterangeorder = sortDateRangeOrder
  ) => {
    setTempSortNameOrder(sortNameOrder);
    setTempSortDateRangeOrder(sortDateRangeOrder);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setTempSelectedStatus(selectedStatus);
    const params = createParams(
      selectedstatus,
      startdate,
      enddate,
      sortnameorder,
      sortdaterangeorder,
      searchVal,
      pageVal,
      sizeVal
    );
    getCampaign(params);
    setOpenSortModal(false);
    setOpenFilterModal(false);
  };

  return (
    <>
      <div className={classes.root}>
        {isSearch && (
          <Flex
            align="center"
            auto
            style={{ marginBottom: "12px", marginTop: "12px" }}
            id="search-campaign"
          >
            <TextField
              variant="outlined"
              size="small"
              value={search}
              onChange={handleSearch}
              className={classes.textField}
              placeholder={t("campaign_management.search")}
              fullWidth
              onKeyDown={onKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {search && (
                      <IconButton
                        onClick={() => {
                          setSearch("");
                          handleSubmitApply("");
                        }}
                        size="small"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
            />
            <ButtonComponent
              id="search"
              className={cn(
                classes.buttonSearch,
                "btn--rounder",
                "btn--primary--filled",
                "ml-2"
              )}
              title={
                <span
                  style={{
                    whiteSpace: "nowrap",
                    display: "inline-block"
                  }}
                >
                  {t("common.btn.search")}
                </span>
              }
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={() => handleSubmitSearch(search)}
              style={{
                minWidth: "fit-content",
                padding: "6px 16px"
              }}
            />
          </Flex>
        )}

        {isShowFilter && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "14px",
              gap: "7px"
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <ButtonComponent
                id="sort-campaign"
                className={cn(
                  "MuiButtonBase-root MuiButton-root MuiButton-contained btn--rounder btn--primary",
                  "text-uppercase MuiButton-containedSizeSmall MuiButton-sizeSmall"
                )}
                title={
                  <span
                    className="MuiButton-label"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <SortRounded style={{ marginRight: "4px" }} />
                    <p className="ml-1"> {t("common.btn.sort")}</p>
                  </span>
                }
                backgroundColor="#05AE34"
                color="white"
                handleClickButton={handleToggleSortModal}
                style={{
                  minWidth: "fit-content",
                  padding: "6px 16px",
                  background: "#05AE34"
                }}
              />

              {openSortModal && (
                <div
                  ref={sortModalRef}
                  className="absolute left-0 w-full bg-white py-3"
                  style={{
                    zIndex: 3,
                    borderRadius: "8px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#C1C1C1FF",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                    top: "100%",
                    marginTop: "4px",
                    position: "absolute",
                    maxWidth: "290px",
                    width: "100%"
                  }}
                >
                  <p className="px-3 mb-2">{t("campaign_management.name")}</p>

                  {/* name: a-z */}
                  <div className="px-2 mb-1">
                    <Button
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E1E1E1FF")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          sortNameOrder === "name,asc" ? "#d8fcd2" : "#fff")
                      }
                      onClick={() => handleSortName("name,asc")}
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        width: "100%",
                        color: "#333",
                        backgroundColor:
                          sortNameOrder === "name,asc" ? "#d8fcd2" : "#fff"
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "16px",
                          height: "16px",
                          borderRadius: "50%",
                          border:
                            sortNameOrder === "name,asc"
                              ? "1px solid #05AE34"
                              : "1px solid #C1C1C1FF",
                          marginRight: "8px",
                          backgroundColor:
                            sortNameOrder === "name,asc" ? "#05AE34" : "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            backgroundColor:
                              sortNameOrder === "name,asc"
                                ? "#fff"
                                : "transparent", // Đổi màu thành "transparent" khi không được chọn
                            transition: "background-color 0.3s ease" // Thêm hiệu ứng chuyển màu
                          }}
                        />
                      </div>

                      <div>A - Z</div>
                    </Button>
                  </div>

                  {/* name: z-a */}
                  <div className="px-2">
                    <Button
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E1E1E1FF")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          sortNameOrder === "name,desc" ? "#d8fcd2" : "#fff")
                      }
                      onClick={() => handleSortName("name,desc")}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        width: "100%",
                        color: "#333",
                        backgroundColor:
                          sortNameOrder === "name,desc" ? "#d8fcd2" : "#fff"
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "16px",
                          height: "16px",
                          borderRadius: "50%",
                          border:
                            sortNameOrder === "name,desc"
                              ? "1px solid #05AE34"
                              : "1px solid #C1C1C1FF",
                          marginRight: "8px",
                          backgroundColor:
                            sortNameOrder === "name,desc" ? "#05AE34" : "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            backgroundColor:
                              sortNameOrder === "name,desc"
                                ? "#fff"
                                : "transparent",
                            transition: "background-color 0.3s ease"
                          }}
                        />
                      </div>

                      <div>Z - A</div>
                    </Button>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      marginTop: "12px",
                      marginBottom: "12px",
                      backgroundColor: "#C1C1C1FF"
                    }}
                  />

                  <p className="px-3 mb-2">{t("campaign_management.time")}</p>

                  {/* newest-oldest */}
                  <div className="px-2 mb-1">
                    <Button
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E1E1E1FF")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          sortDateRangeOrder === "createdAt,desc"
                            ? "#d8fcd2"
                            : "#fff")
                      }
                      onClick={() => handleSortDateRange("createdAt,desc")}
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        width: "100%",
                        color: "#333",
                        backgroundColor:
                          sortDateRangeOrder === "createdAt,desc"
                            ? "#d8fcd2"
                            : "#fff"
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "16px",
                          height: "16px",
                          borderRadius: "50%",
                          border:
                            sortDateRangeOrder === "createdAt,desc"
                              ? "1px solid #05AE34"
                              : "1px solid #C1C1C1FF",
                          marginRight: "8px",
                          backgroundColor:
                            sortDateRangeOrder === "createdAt,desc"
                              ? "#05AE34"
                              : "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            backgroundColor:
                              sortDateRangeOrder === "createdAt,desc"
                                ? "#fff"
                                : "transparent",
                            transition: "background-color 0.3s ease"
                          }}
                        />
                      </div>
                      <div>
                        <span style={{ textTransform: "none" }}>
                          {t("campaign_management.newest_oldest")}
                        </span>
                      </div>
                    </Button>
                  </div>

                  {/* oldest-newest */}
                  <div className="px-2 mb-1">
                    <Button
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E1E1E1FF")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          sortDateRangeOrder === "createdAt,asc"
                            ? "#d8fcd2"
                            : "#fff")
                      }
                      onClick={() => handleSortDateRange("createdAt,asc")}
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        width: "100%",
                        color: "#333",
                        backgroundColor:
                          sortDateRangeOrder === "createdAt,asc"
                            ? "#d8fcd2"
                            : "#fff"
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "16px",
                          height: "16px",
                          borderRadius: "50%",
                          border:
                            sortDateRangeOrder === "createdAt,asc"
                              ? "1px solid #05AE34"
                              : "1px solid #C1C1C1FF",
                          marginRight: "8px",
                          backgroundColor:
                            sortDateRangeOrder === "createdAt,asc"
                              ? "#05AE34"
                              : "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            backgroundColor:
                              sortDateRangeOrder === "createdAt,asc"
                                ? "#fff"
                                : "transparent",
                            transition: "background-color 0.3s ease"
                          }}
                        />
                      </div>
                      <div>
                        {" "}
                        <span style={{ textTransform: "none" }}>
                          {t("campaign_management.oldest_newest")}
                        </span>
                      </div>
                    </Button>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      marginTop: "12px",
                      marginBottom: "12px",
                      backgroundColor: "#C1C1C1FF"
                    }}
                  />

                  {/* reset va apply ben sort */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "12px",
                      marginTop: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px"
                    }}
                  >
                    <ButtonComponent
                      id="reset"
                      handleClickButton={handleResetAllSort}
                      className={cn("btn--grayMain")}
                      title={
                        <span className="MuiButton-label">
                          {t("common.btn.reset")}
                        </span>
                      }
                      backgroundColor="#05AE34"
                      color="white"
                      style={{
                        minWidth: "fit-content",
                        padding: "6px 16px",
                        background: "#05ae34"
                      }}
                    />
                    <ButtonComponent
                      id="apply"
                      handleClickButton={handleSubmitApply}
                      className={cn(
                        classes.buttonFilter,
                        "btn--rounder",
                        "btn--primary--filled"
                      )}
                      title={
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            display: "inline-block"
                          }}
                        >
                          {t("common.btn.apply")}
                        </span>
                      }
                      backgroundColor="#05AE34"
                      color="white"
                      style={{
                        minWidth: "fit-content",
                        padding: "6px 16px"
                      }}
                    />
                  </div>
                </div>
              )}

              {/* {openSortModal && (
                <div
                  className="fixed top-0 left-0"
                  onClick={() => setOpenSortModal(false)}
                  style={{
                    // backgroundColor: "rgba(51, 51, 51, 0.8)",
                    zIndex: 2,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                  }}
                />
              )} */}
            </div>

            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "flex-end"
              }}
              id="filter-campaign"
            >
              <ButtonComponent
                className={cn(
                  "MuiButtonBase-root MuiButton-root MuiButton-contained btn--rounder btn--primary",
                  "text-uppercase MuiButton-containedSizeSmall MuiButton-sizeSmall",
                  "relative"
                )}
                title={
                  <>
                    <span
                      className="MuiButton-label"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "fit-content"
                      }}
                    >
                      <FilterIcon
                        style={{ marginRight: "4px", flexShrink: 0 }}
                      />{" "}
                      <p
                        style={{
                          minWidth: "fit-content"
                        }}
                        className="ml-1"
                      >
                        {t("common.btn.filter")}
                      </p>
                    </span>
                  </>
                }
                backgroundColor="#05AE34"
                color="white"
                handleClickButton={handleToggleFilterModal}
                style={{
                  minWidth: "fit-content",
                  padding: "6px 16px",
                  background: "rgb(5, 174, 52)",
                  color: "white"
                }}
              />

              {openFilterModal && (
                <div
                  ref={filterModalRef}
                  className="absolute left-0 w-full bg-white py-3"
                  style={{
                    zIndex: 3,
                    position: "absolute",
                    top: "100%",
                    marginTop: "4px",
                    borderRadius: "8px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#C1C1C1FF",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                    minWidth: "fit-content",
                    width: "100%"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0 12px",
                      marginBottom: "8px"
                    }}
                  >
                    <p>{t("campaign_management.date_range")}</p>
                    <p
                      onClick={handleResetDateRange}
                      style={{ color: "#23763AFF", cursor: "pointer" }}
                    >
                      {t("common.btn.reset")}
                    </p>
                  </div>

                  {/* chon ngay */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 8px"
                    }}
                  >
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      style={{
                        cursor: "pointer",
                        flex: 1,
                        marginRight: "8px",
                        padding: "8px",
                        borderRadius: "4px",
                        border: "1px solid #C1C1C1FF"
                      }}
                    />
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      style={{
                        cursor: "pointer",
                        flex: 1,
                        marginLeft: "8px",
                        padding: "8px",
                        borderRadius: "4px",
                        border: "1px solid #C1C1C1FF"
                      }}
                    />
                  </div>

                  {/* duong line */}
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      marginTop: "12px",
                      marginBottom: "12px",
                      backgroundColor: "#C1C1C1FF"
                    }}
                  />

                  {/* status */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0 12px",
                      marginBottom: "8px"
                    }}
                  >
                    <p>{t("campaign_management.status.title")}</p>
                    <p
                      onClick={handleResetStatus}
                      style={{ color: "#23763AFF", cursor: "pointer" }}
                    >
                      {t("common.btn.reset")}
                    </p>
                  </div>

                  {/* select status */}
                  {/* <div className="relative inline-block w-64 px-2"> */}
                  <div
                    style={{
                      position: "relative",
                      border: "1px solid #D1D5DB",
                      borderRadius: "0.375rem",
                      padding: "0.5rem 1rem",
                      cursor: "pointer",
                      marginLeft: "8px",
                      marginRight: "8px",
                      paddingLeft: "8px",
                      paddingRight: "8px"
                    }}
                    onClick={() => setIsOpen(true)}
                  >
                    {isOpen && (
                      <div
                        ref={filterPopupRef}
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          zIndex: 3,
                          width: "100%",
                          backgroundColor: "white",
                          border: "1px solid #D1D5DB",
                          borderRadius: "0.375rem",
                          boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                          marginTop: "4px"
                        }}
                      >
                        <div style={{ padding: "0.25rem 0" }}>
                          {/* Checkbox for "All" option */}
                          <div
                            style={{
                              padding: "0.5rem 1rem",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center"
                            }}
                            onClick={() => handleSelect("all")}
                          >
                            <div
                              style={{
                                position: "relative",
                                width: "20px",
                                height: "20px",
                                borderRadius: "4px",
                                backgroundColor:
                                  selectedStatus.length === dataStatus.length
                                    ? "#05ae34"
                                    : "#ffffff",
                                border:
                                  selectedStatus.length === dataStatus.length
                                    ? "1px solid #f4fef4ff"
                                    : "1px solid #c1c1c1",
                                marginRight: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              {selectedStatus.length === dataStatus.length && (
                                <CheckOutlined
                                  style={{
                                    paddingLeft: "3px",
                                    paddingRight: "3px",
                                    fill: "#ffffff"
                                  }}
                                />
                              )}
                            </div>
                            <span>{t("campaign_management.status.all")}</span>
                          </div>

                          {dataStatus.map((status) => (
                            <div
                              key={status.id}
                              style={{
                                padding: "0.5rem 1rem",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center"
                              }}
                              onClick={() => handleSelect(status.name)}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "4px",
                                  backgroundColor: selectedStatus.includes(
                                    status.name
                                  )
                                    ? "#05ae34"
                                    : "#ffffff",
                                  border: selectedStatus.includes(status.name)
                                    ? "1px solid #f4fef4ff"
                                    : "1px solid #c1c1c1",
                                  marginRight: "8px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                {selectedStatus.includes(status.name) && (
                                  <CheckOutlined
                                    style={{
                                      paddingLeft: "3px",
                                      paddingRight: "3px",
                                      fill: "#ffffff"
                                    }}
                                  />
                                )}
                              </div>
                              <span>{status.value}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      <span>
                        {selectedStatus.length === 0
                          ? t("common.btn.choose_status")
                          : selectedStatus.length < dataStatus.length
                          ? selectedStatus
                              .map((status) =>
                                t(`campaign_management.status.${status}`)
                              )
                              .join(", ")
                          : t("campaign_management.status.all")}
                      </span>

                      <ChevronDown
                        style={{
                          width: "16px",
                          height: "16px",
                          transition: "transform 0.3s ease",
                          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)"
                        }}
                      />
                    </div>
                  </div>
                  {/* </div> */}

                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      marginTop: "12px",
                      marginBottom: "12px",
                      backgroundColor: "#C1C1C1FF"
                    }}
                  />

                  {/* reset va apply all o filter */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "12px",
                      paddingLeft: "8px",
                      paddingRight: "8px"
                    }}
                  >
                    <ButtonComponent
                      id="reset"
                      handleClickButton={handleResetAllFilter}
                      className={cn("btn--grayMain")}
                      title={
                        <span
                          style={{
                            minWidth: "fit-content"
                          }}
                          className="MuiButton-label"
                        >
                          {t("common.btn.reset_all")}
                        </span>
                      }
                      backgroundColor="#05AE34"
                      color="white"
                      style={{
                        minWidth: "fit-content",
                        padding: "6px 16px",
                        background: "#05ae34"
                      }}
                    />
                    <ButtonComponent
                      id="apply"
                      handleClickButton={handleClickFilter}
                      className={cn(
                        classes.buttonFilter,
                        "btn--rounder",
                        "btn--primary--filled"
                      )}
                      title={
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            display: "inline-block"
                          }}
                        >
                          {t("common.btn.apply")}
                        </span>
                      }
                      backgroundColor="#05AE34"
                      color="white"
                      style={{
                        minWidth: "fit-content",
                        padding: "6px 16px"
                      }}
                    />
                  </div>
                </div>
              )}

              {/* {openFilterModal && (
                <div
                  className="fixed top-0 left-0"
                  onClick={() => setOpenFilterModal(false)}
                  style={{
                    // backgroundColor: "rgba(51, 51, 51, 0.8)",
                    zIndex: 2,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                  }}
                />
              )} */}
            </div>
          </div>
        )}

        <Paper className={classes.paper} elevation={0}>
          <TableContainer
            style={{
              height: rows.length > 0 ? "calc(100vh - 350px)" : "52px",
              overflowY: rows.length > 0 ? "auto" : "hidden"
            }}
          >
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead classes={classes} t={t} />
              <TableBody>
                {rows.map((row, index) => {
                  if (row.totalCus === 0) return null;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => onViewCampaign(row.id, row.pageId)}
                      className={[classes.heightHeader, classes.rowItem]}
                    >
                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {data?.number * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        <Flex justify="space-between" align="center">
                          <span className="text-truncate">{row.id}</span>
                          <Tooltip title={copied ? t("copied") : t("copy")}>
                            <IconButton
                              className={classes.copyButton}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCopy(row.id);
                              }}
                              size="small"
                            >
                              <img
                                src="/image/Clipboard-alt.svg"
                                alt="Copy"
                                style={{ width: "16px", height: "16px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Flex>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        <span className="text-truncate">{row.name}</span>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {row.pageName}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {row.userFullName}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {moment(row.createdAt).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <CampaignStatusLabel
                            size="small"
                            type={row?.status}
                            message={
                              t(`campaign_management.status.${row.status}`) ||
                              row.status
                            }
                          />
                        </div>
                        {/* <span style={getStatusStyle(row.status)}>
                          {t(`campaign_management.status.${row.status}`) ||
                            row.status}
                        </span> */}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {moment(row.beginTime).isValid()
                          ? moment(row.beginTime).format("DD/MM/YYYY HH:mm")
                          : ""}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {row.totalScenario}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {row.totalCus}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {row.totalCustomersDone}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {row.totalCustomersError}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {row.totalCustomersNotSend}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {row.totalMes}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {row.totalSuccess}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {row.totalFail}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={[classes.witdhOrd, classes.p0]}
                      >
                        {row.totalNotSend}
                      </TableCell>

                      {/* <TableCell
                        align="right"
                        className={cn(classes.tableCell, classes.numberCell)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderLeft: "0.2px solid rgb(246, 239, 239)",
                          boxShadow: "none",
                          backgroundColor: "#fafafa",
                          position: "sticky",
                          right: 0,
                          top: 0,
                          zIndex: 1
                        }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event bubbling
                          onViewScenario(row.id); // Call the function when the cell is clicked
                        }}
                      >
                        <Tooltip title={t("campaign_management.scenario.detail")}>
                          <Button
                            className={classes.actionButton}
                            style={{
                              width: "100%", // Make the button full width
                              height: "100%", // Make the button full height
                              padding: 0 // Remove padding
                            }}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent event bubbling
                              onViewScenario(row.id); // Call the function when the button is clicked
                            }}
                          >
                            <div className={classes.actionBtnDetail}>
                              <p className={classes.actionTitle}>
                                {t("campaign_management.scenario.name")}
                              </p>
                            </div>
                          </Button>
                        </Tooltip>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {rows?.length === 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
                minHeight: "69px",
                backgroundColor: "#ffffff"
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  width: "100%",
                  color: "rgba(0,0,0,.5)",
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              >
                {t("update.no_data")}
              </p>
            </div>
          )}
        </Paper>
        {isPagination && rows.length > 0 && (
          <Flex justify="space-between" align="center" className="px-3 py-2">
            <TablePagination
              style={{ margin: "0px 0" }}
              rowsPerPageOptions={[types.PAGINATION.SIZE, 20, 30, 50]}
              component="div"
              rowsPerPage={rowsPerPage}
              count={Math.ceil(data?.numberOfElements / rowsPerPage)}
              page={page + 1}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={t("update.display")}
            />
            <Pagination
              style={{ top: 0 }}
              count={Math.ceil(data?.totalMatch / rowsPerPage)}
              page={page + 1}
              onChange={handleChange}
            />
          </Flex>
        )}
      </div>
    </>
  );
}

ListCampaign.propTypes = {
  data: PropTypes.object,
  search: PropTypes.string,
  isSearch: PropTypes.bool,
  isPagination: PropTypes.bool,
  getCampaign: PropTypes.func,
  handleGetCampaign: PropTypes.func
};

ListCampaign.defaultProps = {
  isPagination: true,
  isSearch: true,
  handleGetCampaign: () => {},
  getCampaign: () => {},
  data: {},
  search: null
};
