import { Card } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import homeApi from "../../../../api/homeApi";

const FBTagChart = ({ style, ...res }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  // gọi api getStatistic?page=0&size=15&sort=desc
  useEffect(() => {
    (async () => {
      try {
        const res = await homeApi.getTagFacebookStatistic({
          page: 0,
          size: 15,
          sort: "desc"
        });
        setData(res);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [selectedDate]);

  const chartCustomerPerFanpages = useMemo(() => {
    const filteredData = data.filter(
      (item) => item.tagName && item.totalCus !== undefined
    );

    const uniqueTags = {};
    filteredData.forEach((item) => {
      if (!uniqueTags[item.tagName]) {
        uniqueTags[item.tagName] = item.totalCus;
      } else {
        uniqueTags[item.tagName] += item.totalCus;
      }
    });

    const labels = Object.keys(uniqueTags);
    const dataValues = Object.values(uniqueTags);

    return {
      labels,
      datasets: [
        {
          label: t("dashboard.customer_count"),
          data: dataValues,
          backgroundColor: "#a8d5ba",
          borderColor: "#4cae50",
          borderWidth: 1
        }
      ]
    };
  }, [data, t]);

  const barOptions = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true
      }
    },
    scales: {
      x: {
        beginAtZero: true
      }
    }
  };

  return (
    <Card style={style} {...res}>
      <h3
        style={{
          margin: 0
        }}
      >
        {t("customer_management.list.facebook_tag")}
      </h3>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <Bar data={chartCustomerPerFanpages} options={barOptions} />
      </div>
    </Card>
  );
};

export default FBTagChart;
